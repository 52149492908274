import React, { Component } from "react";
import { LoginBox, ButtonForm } from "../../components";
import { connect } from "react-redux";
import { Col } from "antd";
import ReactCodeInput from "react-code-input";
import { AuthAction } from "../../store/actions";
import { withRouter } from "react-router-dom";
import { Colors } from "../../config";
class VerifyCode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: null,
      code: "",
      error: "",
      timer: false,
      countDown: 60,
      timerId: null,
      renderCodeInput: false,
    };
  }

  componentDidMount() {
    //console.log("THIS.PROPS VERIFY CODE", this.props);
    const {history} = this.props;
    if (history.location.state != undefined) {
      if (history.location.state.email != undefined) {
        this.setState({
          email: history.location.state.email,
        });
      } else {
        history.push({
          pathname: "/login",
        });
      }
    } else {
      history.push({
        pathname: "/login",
      });
    }
  }

  apiCall = () => {
    let isValid = this.state.error;
    const { verifycode, history } = this.props;
    if (isValid == "") {
      verifycode(
        {
          email: this.state.email,
          code: this.state.code,
        },
        (data, error) => {
          data
            ? history.push({
                pathname: "/reset-password",
                state: {
                  email: this.state.email,
                },
              })
            : console.log("wrong code");
        }
      );
    } else {
      console.error("Error: ", isValid);
    }
  };

  handleChanged = (val) => {
    //console.log("val is val is : ", val);
    this.setState({
      code: val,
    });
    this.handleErrors(val);
  };
  handleErrors = (a) => {
    let error = "";
    if (a.length == 0) {
      error = "Please enter the code ";
    } else if (a.length < 4 && a.length > 0) {
      error = "Incomplete Code ";
    } else {
      error = "";
    }
    this.setState({ error: error });
    return error;
  };

  resendApiCall = () => {
    const { forgetpassword } = this.props;
    this.setState({ code: "", renderCodeInput: !this.state.renderCodeInput });
    var id = setInterval(() => {
      if (this.state.countDown === 1) {
        this.setState({ timer: false, countDown: 60 });
        clearInterval(this.state.timerId);
      } else {
        this.setState({ countDown: this.state.countDown - 1 });
      }
    }, 1000);

    this.setState({ timerId: id, timer: true });
    forgetpassword({ email: this.state.email }, () => {
      //console.log("Reset Fields");
    });
  };
  render() {
    const { verifyCodeLoader } = this.props;
    const { countDown, timer } = this.state;
    return (
      <LoginBox
        isLabel="true"
        countdown={countDown}
        apiCall={this.resendApiCall}
        timer={timer}
      >
        <Col span={24} style={{ marginTop: "20px" }}>
          <h3>
            <b>Reset your Password</b>
          </h3>
          <p>
            Enter the verification code that we just
            <br /> sent you on your email.
          </p>
        </Col>
        <Col span={16}>
          <ReactCodeInput
            type="number"
            inputStyle={{
              height: "40px",
              width: "40px",
              textAlign: "center",
              fontFamily: "monospace",
              margin: "4px",
              MozAppearance: "textfield",
              borderRadius: "3px",
              //fontSize: '14px',
              //paddingLeft: '7px',
              backgroundColor: "white",
              color: "black",
              border: "1px solid gray",
            }}
            onChange={(e) => {
              //console.log("VALUE IS", e);
              this.handleChanged(e);
            }}
          />
        </Col>
        <Col span={16}>
          <span style={{ color: Colors.Red }}>{this.state.error}</span>
        </Col>
        <Col span={24}></Col>
        <Col span={24}>
          <ButtonForm
            onClick={() => {
              this.apiCall();
            }}
            loading={verifyCodeLoader}
          >
            Verify
          </ButtonForm>
        </Col>
      </LoginBox>
    );
  }
}

function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    verifycode: (payload, cb) => {
      dispatch(AuthAction.VerifyCode(payload, cb));
    },
    forgetpassword: (email, cb) => {
      dispatch(AuthAction.ForgetPassword(email, cb));
    },
  };
}
export default withRouter(
  connect(
    (storeState) => ({
      verifyCodeLoader: storeState.AuthReducer.verifyCodeLoader,
    }),
    (mapStateToProps, mapDispatchToProps)
  )(VerifyCode)
);
