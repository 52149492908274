import Axios from "axios";
//import AsyncStorage from "@react-native-community/async-storage";
import { AuthAction } from "../../store/actions";
import Store from "../../store";
//import { NavigationService } from "..";
import {API_BASE_URL} from "../variables";
//const { baseUrl } = API_BASE_URL

  
Axios.interceptors.response.use((response) => {
    return response
}, ({ response }) => {
    if (response.status == 401) {
        //console.log("UNAUTHORIZED ACCESS");
        try {
            localStorage.removeItem("eventauth");
            Store.dispatch(AuthAction.SignoutSuccess());
            window.location.reload();
        }
        catch (err) {
        }
    }
    return response
})

Axios.interceptors.request.use(function (config) {
    if(localStorage.getItem("eventauth")){
        config.headers={
            ...config?.headers,
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "POST, GET, OPTIONS, PATCH, DELETE",
            "user-id":JSON.parse(localStorage.getItem("eventauth"))?.user?.id,
            "x-auth-token":JSON.parse(localStorage.getItem("eventauth"))?.x_auth_token
        }
        return config;
    }
    else{
        return config;
    }
  }, function (error) {
    // Do something with request error
    return Promise.reject(error);
  });
const CancelToken = Axios.CancelToken;
  

export default class ApiCaller {

    static Get = (endPoint = "", headers = {}, customUrl = "") => {
        if (headers["user_id"]) {
            headers["user-id"] = headers["user_id"]
            headers["access-token"] = headers["access_token"]
        }
        return Axios.get(customUrl ? customUrl : `${API_BASE_URL}${endPoint}`, { timeout: 10000,
            headers: { "Content-Type": "application/json", ...headers }
        }).then((res) => res).catch((err) => err.response)
    }

    static Post = (endPoint = "", body = {}, headers = {}) => {
        //console.log("HEADERS ARE THIS IS :", headers);
        if (headers["user_id"]) {
            headers["user-id"] = headers["user_id"]
            headers["access-token"] = headers["access_token"]
        }
        return Axios.post(`${API_BASE_URL}${endPoint}`, body, { timeout: 10000,
            headers: { "Content-Type": "application/json", ...headers }
        }).then((res) => res).catch((err) => err.response)
    }

    static Put = (endPoint = "", body = {}, headers = {}, customUrl = "") => {
        if (headers["user_id"]) {
            headers["user-id"] = headers["user_id"]
            headers["access-token"] = headers["access_token"]
        }
        return Axios.put((customUrl ? customUrl : `${API_BASE_URL}${endPoint}`), body, { timeout: 10000,
            headers: { "Content-Type": "application/json", ...headers }
        }).then((res) => res).catch((err) => err.response)
    }

    static Delete = (endPoint = "", headers = {}, body = {}, customUrl = "") => {
        if (headers["user_id"]) {
            headers["user-id"] = headers["user_id"]
            headers["access-token"] = headers["access_token"]
        }
        return Axios.delete((customUrl ? customUrl : `${API_BASE_URL}${endPoint}`), { timeout: 10000,
            headers,
            data: body
        }).then((res) => res).catch((err) => err.response)
    }
}

export { CancelToken, ApiCaller };