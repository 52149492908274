import {  AuthMiddleware, FormMiddleware, UserMiddleware, EventMiddleware, TransactionMiddleware} from "../middlewares"
import { takeLatest, all } from 'redux-saga/effects'
import {
    SIGNIN,
    SIGNOUT,
    FORGETPASSWORD,
    VERIFYCODE,
    RESETPASSWORD,
    GETFORMS,
    UPLOADFORMS,
    DELETEFORMS,
    GETUSERSTATS,
    GETUSERS,
    GETUSERDETAIL,
    GETAPPDOWNLOADCOUNT,
    GETUSERTYPECOUNT,
    GETEVENTSTATSCOUNT,
    GETSUBSCRIBEDUSERCOUNT,
    USERSTATUSUPDATE,
    GETEVENTS,
    DELETEEVENTS,
    CHANGEPASSWORD,
    GETTRANSACTIONS
} from "../constants"

export function* Sagas() {
    yield all([
        yield takeLatest(SIGNIN, AuthMiddleware.SignIn),
        yield takeLatest(SIGNOUT, AuthMiddleware.Signout),
        yield takeLatest(FORGETPASSWORD, AuthMiddleware.ForgetPassword),
        yield takeLatest(VERIFYCODE, AuthMiddleware.VerifyCode),
        yield takeLatest(CHANGEPASSWORD, AuthMiddleware.ChangePassword),
        yield takeLatest(RESETPASSWORD, AuthMiddleware.ResetPassword),
        yield takeLatest(GETFORMS, FormMiddleware.GetForms),
        yield takeLatest(UPLOADFORMS, FormMiddleware.UploadForms),
        yield takeLatest(DELETEFORMS, FormMiddleware.DeleteForms),
        yield takeLatest(GETUSERSTATS, UserMiddleware.GetUserStats),
        yield takeLatest(GETUSERS, UserMiddleware.GetUsers),
        yield takeLatest(GETUSERDETAIL, UserMiddleware.GetUsersDetails),
        yield takeLatest(USERSTATUSUPDATE, UserMiddleware.UserStatusUpdate),
        yield takeLatest(GETAPPDOWNLOADCOUNT, UserMiddleware.GetAppDownloadCount),
        yield takeLatest(GETUSERTYPECOUNT, UserMiddleware.GetUserTypeCount),
        yield takeLatest(GETEVENTSTATSCOUNT, UserMiddleware.GetEventStatsCount),
        yield takeLatest(GETSUBSCRIBEDUSERCOUNT, UserMiddleware.GetSubscribedUserCount),
        yield takeLatest(GETEVENTS, EventMiddleware.GetEvents),
        yield takeLatest(DELETEEVENTS, EventMiddleware.DeleteEvent),
        yield takeLatest(GETTRANSACTIONS, TransactionMiddleware.GetTransactions)
    ])
}