import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import {
  Dashboard,
  Forms,
  Users,
  UserDetails,
  Events,
  ChangePassword,
  Transactions
} from "../../containers";
class AppRoutes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="App">
        <Switch>
          <Route exact path="/" component={Dashboard} />
          <Route exact path="/forms" component={Forms} />
          <Route exact path="/users" component={Users} />
          <Route exact path="/users/user-details/:id" component={UserDetails} />
          <Route exact path="/events" component={Events} />
          <Route exact path="/change-password" component={ChangePassword} />
          <Route exact path="/transactions" component={Transactions} />
          <Route exact path="*" render={() => <Redirect to="/" />} />
        </Switch>
      </div>
    );
  }
}

export default connect((state) => ({ storeState: state }))(AppRoutes);
