import { Row, Col } from "antd";
import React, { PureComponent } from "react";
import {
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  Legend,
  Tooltip,
} from "recharts";
import { useMedia } from "use-media";
import { Colors } from "../../config";

const COLORS = [Colors.Cyan, Colors.Secondary, Colors.LightRed];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor="middle"
      dominantBaseline="central"
      fontSize={document.body.clientWidth<=770 ? 7 : 14}
    >
      {`${(percent * 100).toFixed(2)}%`}
    </text>
  );
};
const CustomTooltip = ({ payload }) => {
  //console.log("payload is: ", payload);
  return (
    <Col
      style={{
        margin: "0px",
        padding: "10px",
        backgroundColor: "rgb(255, 255, 255)",
        border: "1px solid rgb(204, 204, 204)",
        whiteSpace: "nowrap",
        height: "2.8rem",
      }}
      span={24}
    >
      <p
        className="recharts-tooltip-label"
        style={{
          color:
            payload?.[0]?.payload?.name == "Event Manager"
              ? Colors.Secondary
              : payload?.[0]?.payload?.name == "Rider"
              ? Colors.Cyan
              : Colors.LightRed,
        }}
      >
        {payload?.[0]?.payload?.name + ": " + payload?.[0]?.payload?.value}
      </p>
    </Col>
  );
};

class PieChartComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }
  static demoUrl =
    "https://codesandbox.io/s/pie-chart-with-customized-label-dlhhj";
  renderLegend = (props) => {
    const { payload } = props;
    //console.log("PROPS PAYLOAD ARE: ", props);
    const COLORS = [Colors.Cyan, Colors.Secondary, Colors.LightRed];
    return (
      <Row
        style={{
          display: "flex",
        }}
        justify="center"
        align="middle"
      >
        {payload.map((entry, index) => (
          <div
            style={{
              display: "flex",
              marginLeft: "1.5rem",
              marginRight: "1.5rem",
            }}
          >
            <Col
              style={{
                borderRadius: 15,
                width: 15,
                height: 15,
                backgroundColor: entry.color,
                margin: 15,
              }}
            ></Col>
            <p
              style={{
                color: entry.color,
                display: "flex",
                justifyContent: "center",
                marginTop: "12px",
                fontSize: "0.8rem",
              }}
            >
              {"       " + entry.value}
            </p>
          </div>
        ))}
      </Row>
    );
  };
  render() {
    const { isWide, isLow, newData } = this.props;
    return (
      <ResponsiveContainer width="100%" height="100%">
        <PieChart width={400} height={400}>
          <Legend
            verticalAlign="bottom"
            payload={[
              {
                id: "em",
                value: "Event Manager",
                type: "circle",
                color: Colors.Secondary,
              },
              { id: "r", value: "Rider", type: "circle", color: Colors.Cyan },
              {
                id: "j",
                value: "Judge",
                type: "circle",
                color: Colors.LightRed,
              },
            ]}
            content={this.renderLegend}
          />
          <Pie
            data={newData}
            cx="50%"
            cy="50%"
            labelLine={false}
            label={renderCustomizedLabel}
            outerRadius={isWide ? 130 : !isLow ? 88 : 91}
            fill="#8884d8"
            dataKey="value"
          >
            {newData.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
          <Tooltip content={<CustomTooltip />} />
        </PieChart>
      </ResponsiveContainer>
    );
  }
}

const MediaWrapper = (props) => {
  const isWide = useMedia({ minWidth: "800px" });
  const isLow = useMedia({ minWidth: "280px" });
  return <PieChartComponent isWide={isWide} isLow={isLow} {...props} />;
};

export default React.memo(MediaWrapper);
