//AUTH Constants

export const SIGNIN = "SIGNIN";
export const SIGNIN_SUCCESS = "SIGNIN_SUCCESS";
export const SIGNIN_FAILURE = "SIGNIN_FAILURE";

export const SIGNOUT = "SIGNOUT";
export const SIGNOUT_SUCCESS = "SIGNOUT_SUCCESS";
export const SIGNOUT_FAILURE = "SIGNOUT_FAILURE";

export const FORGETPASSWORD = "FORGETPASSWORD";
export const FORGETPASSWORD_SUCCESS = "FORGETPASSWORD_SUCCESS";
export const FORGETPASSWORD_FAILURE = "FORGETPASSWORD_FAILURE";

export const CHANGEPASSWORD = "CHANGEPASSWORD";
export const CHANGEPASSWORD_SUCCESS = "CHANGEPASSWORD_SUCCESS";
export const CHANGEPASSWORD_FAILURE = "CHANGEPASSWORD_FAILURE";

export const VERIFYCODE = "VERIFYCODE";
export const VERIFYCODE_SUCCESS = "VERIFYCODE_SUCCESS";
export const VERIFYCODE_FAILURE = "VERIFYCODE_FAILURE";

export const RESETPASSWORD = "RESETPASSWORD";
export const RESETPASSWORD_SUCCESS = "RESETPASSWORD_SUCCESS";
export const RESETPASSWORD_FAILURE = "RESETPASSWORD_FAILURE";

//App Constants

export const GETUSERS = "GETUSERS"; 
export const GETUSERS_SUCCESS = "GETUSERS_SUCCESS";
export const GETUSERS_FAILURE = "GETUSERS_FAILURE";

export const GETUSERDETAIL = "USERDETAIL";
export const GETUSERDETAIL_SUCCESS = "USERDETAIL_SUCCESS";
export const GETUSERDETAIL_FAILURE = "USERDETAIL_FAILURE";

export const USERSTATUSUPDATE = "USERSTATUSUPDATE";
export const USERSTATUSUPDATE_SUCCESS = "USERSTATUSUPDATE_SUCCESS";
export const USERSTATUSUPDATE_FAILURE = "USERSTATUSUPDATE_FAILURE";

export const GETUSERSTATS = "USERSTATS";
export const GETUSERSTATS_SUCCESS = "USERSTATS_SUCCESS";
export const GETUSERSTATS_FAILURE = "USERSTATS_FAILURE";

export const GETEVENTSTATSCOUNT = "EVENTSTATS";
export const GETEVENTSTATSCOUNT_SUCCESS = "EVENTSTATS_SUCCESS";
export const GETEVENTSTATSCOUNT_FAILURE = "EVENTSTATS_FAILURE";

export const GETUSERTYPECOUNT = "USERTYPECOUNT";
export const GETUSERTYPECOUNT_SUCCESS = "USERTYPECOUNT_SUCCESS";
export const GETUSERTYPECOUNT_FAILURE = "USERTYPECOUNT_FAILURE";

export const GETSUBSCRIBEDUSERCOUNT = "SUBSCRIBEDUSERCOUNT";
export const GETSUBSCRIBEDUSERCOUNT_SUCCESS = "SUBSCRIBEDUSERCOUNT_SUCCESS";
export const GETSUBSCRIBEDUSERCOUNT_FAILURE = "SUBSCRIBEDUSERCOUNT_FAILURE";

export const GETAPPDOWNLOADCOUNT = "APPDOWGETAPPDOWNLOADCOUNT";
export const GETAPPDOWNLOADCOUNT_SUCCESS = "APPDOWGETAPPDOWNLOADCOUNT_SUCCESS";
export const GETAPPDOWNLOADCOUNT_FAILURE = "APPDOWGETAPPDOWNLOADCOUNT_FAILURE";

export const GETEVENTS = "GETEVENTS"; 
export const GETEVENTS_SUCCESS = "GETEVENTS_SUCCESS";
export const GETEVENTS_FAILURE = "GETEVENTS_FAILURE";

export const CLEARDASHBOARD = "CLEARDASHBOARD";

export const GETTRANSACTIONS = "GETTRANSACTIONS"; 
export const GETTRANSACTIONS_SUCCESS = "GETTRANSACTIONS_SUCCESS";
export const GETTRANSACTIONS_FAILURE = "GETTRANSACTIONS_FAILURE";

export const GETFORMS = "GETFORMS";
export const GETFORMS_SUCCESS = "GETFORMS_SUCCESS";
export const GETFORMS_FAILURE = "GETFORMS_FAILURE";

export const UPLOADFORMS = "UPLOADFORMS";
export const UPLOADFORMS_SUCCESS = "UPLOADFORMS_SUCCESS";
export const UPLOADFORMS_FAILURE = "UPLOADFORMS_FAILURE";

export const DELETEFORMS = "DELETEFORMS";
export const DELETEFORMS_SUCCESS = "DELETEFORMS_SUCCESS";
export const DELETEFORMS_FAILURE = "DELETEFORMS_FAILURE";

export const DELETEEVENTS = "DELETEEVENTS";
export const DELETEEVENTS_SUCCESS = "DELETEEVENTS_SUCCESS";
export const DELETEEVENTS_FAILURE = "DELETEEVENTS_FAILURE";
//Loader
export const LOADER_TRUE = 'LOADER_TRUE';
export const LOADER_FALSE = 'LOADER_FALSE';