import { EventAction } from "../../actions";
import { ApiCaller } from "../../../config";
import Info from "../../../components/message";
import {
  GETEVENTS_ENDPOINT,
  DELETEEVENTS_ENDPOINT,
} from "../../../config/variables";
import { put } from "redux-saga/effects";

export default class EventMiddleware {
  static *GetEvents(payload) {
    const { like, limit, offset } = payload;
    try {
      let res = yield ApiCaller.Get(
        `${GETEVENTS_ENDPOINT}?like=${like}&limit=${limit}&offset=${offset}`
      );
      if (res.status == 200) {
        yield put(EventAction.GetEventsSuccess(res.data.data));
      } else {
        yield put(EventAction.GetEventsFailure());
        Info("error", res.data.error.message);
      }
    } catch (err) {
      yield put(EventAction.GetEventsFailure());
    }
  }

  static *DeleteEvent(params) {
    const { cb, limit, offset, like, id } = params;
    //console.log("PAYLOAD: I AM HIT");
    try {
      let res = yield ApiCaller.Delete(`${DELETEEVENTS_ENDPOINT}/${id}`);
      if (res.status == 200) {
        yield put(EventAction.DeleteEventSuccess(res.data.data));
        Info("success", res.data.data.message);
        if (cb) {
          cb(true);
        }
        yield put(EventAction.GetEvents(like, limit, offset));
      } else {
        yield put(EventAction.DeleteEventFailure());
        Info("error", res.data.error.message);
        if (cb) {
          cb(false);
        }
      }
    } catch (err) {
      yield put(EventAction.DeleteEventFailure());
    }
  }
}
