import React from 'react';
import { DatePicker, Row, Col } from "antd";
import {Colors} from "../../config"
import { RiCalendarLine } from 'react-icons/ri';
import styled from "styled-components";
import moment from "moment";
const { RangePicker } = DatePicker;
const monthFormat = 'MMM YYYY';
const MonthPicker = styled(RangePicker)`
  color: ${Colors.Primary};
  width: 200px;
  border-radius: 10px;
  border-color: ${Colors.Primary};
  cursor: pointer;
  height: 2.5rem;
  background-color: ${Colors.LightCyan};
  & .ant-picker-input > input {
    color: ${Colors.Primary};
  }
`;

const MonthRangePicker = (props) => {
  return <MonthPicker  {...props}>
</MonthPicker>;
}

export default MonthRangePicker
