import React from "react";
import { connect } from "react-redux";
import { Row, Col, Pagination, Select } from "antd";
import { Blip, SearchBar, HeaderComponent, UserTable } from "../../components";
import { withRouter } from "react-router-dom";
import { Colors } from "../../config";
import { UserAction } from "../../store/actions";
import {
  RiSearchLine,
  RiCheckLine,
  RiArrowDropDownLine,
  RiArrowDropUpLine,
} from "react-icons/ri";
import "../../index.css";
const { Option } = Select;
class Users extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userType: "eventmanager",
      userSelectType: "event manager",
      offset: 0,
      like: "",
      isUserTypeClicked: false,
      isStatusClicked: false,
      isUserTypeSecondClicked: false,
      userStatus: "",
      current: 1,
    };
  }
  handleSearch = (val) => {
    const { getusers } = this.props;
    const { userSelectType, userStatus } = this.state;
    getusers(10, 0, userSelectType, userStatus, val);
    this.setState({ like: val, current: 1, offset: 0 });
  };
  handleStatus = (userStatus) => {
    const { getusers } = this.props;
    const { like } = this.state;
    getusers(10, 0, "event manager", userStatus, like);
    this.setState({ userStatus: userStatus, current: 1, offset: 0 });
  };
  onUserTypeChange = (userType) => {
    if (userType == "event manager") {
      this.setState({
        userType: "eventmanager",
      });
    } else if (userType == "rider") {
      this.setState({
        userType: "rider",
      });
    } else if (userType == "judge") {
      this.setState({
        userType: "judge",
      });
    } else if (userType == "judge/rider") {
      this.setState({
        userType: "judgerider",
      });
    }
    const { getusers } = this.props;
    const { userStatus, like } = this.state;
    getusers(10, 0, userType, userStatus, like);
    this.setState({ userSelectType: userType, current: 1, offset: 0 });
  };
  componentDidMount() {
    const { getusers } = this.props;
    getusers(10, 0, "event manager", "");
  }

  handlePages = (pageNumber) => {
    const limit = 10;
    let offset = (pageNumber - 1) * limit;
    const { getusers } = this.props;
    const { userSelectType, userStatus, like } = this.state;
    getusers(limit, offset, userSelectType, userStatus, like);
    this.setState({ offset: offset, current: pageNumber });
  };

  render() {
    const { userCount, userData } = this.props;
    const {
      offset,
      userSelectType,
      like,
      current,
      userStatus,
      userType,
      isStatusClicked,
      isUserTypeClicked,
    } = this.state;
    return (
      <HeaderComponent>
        <Row gutter={[0, 20]} style={{ padding: 40 }}>
          <Col span={24}>
            <Row gutter={[0, 10]}>
              <Col
                xs={24}
                sm={24}
                md={userType == "eventmanager" ? 14 : 8}
                lg={userType == "eventmanager" ? 14 : 8}
                xl={userType == "eventmanager" ? 14 : 8}
                align="start"
              >
                <Row>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <h1>
                      <b>
                        {userType == "eventmanager"
                          ? "Event Manager  "
                          : userType == "rider"
                          ? "Rider  "
                          : userType == "judge"
                          ? "Judge  "
                          : "Judge/Rider  "}
                      </b>
                      <Blip /> {userCount ? userCount : 0}
                    </h1>
                  </Col>
                  {userType == "eventmanager" ? (
                    <Col xs={24} sm={24} md={10} lg={10} xl={10}>
                      <SearchBar
                        placeholder="Search here"
                        prefix={
                          <RiSearchLine style={{ color: Colors.Primary }} />
                        }
                        onChange={(e) => {
                          this.handleSearch(e.target.value);
                        }}
                      />
                    </Col>
                  ) : null}
                </Row>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={userType == "eventmanager" ? 10 : 16}
                lg={userType == "eventmanager" ? 10 : 16}
                xl={userType == "eventmanager" ? 10 : 16}
              >
                <Row
                  justify="center"
                  gutter={[userType == "eventmanager" ? 15 : 40, 10]}
                >
                  {userType == "eventmanager" ? (
                    <Col xs={24} sm={24} md={9} lg={9} xl={9}>
                      <Select
                        suffixIcon={
                          !isStatusClicked ? (
                            <RiArrowDropDownLine
                              style={{
                                color: Colors.Primary,
                                fontSize: "16px",
                              }}
                            />
                          ) : (
                            <RiArrowDropUpLine
                              style={{
                                color: Colors.Primary,
                                fontSize: "16px",
                              }}
                            />
                          )
                        }
                        defaultValue="View All"
                        //onClick={()=> {this.setState({isStatusClicked: !isStatusClicked})}}
                        onDropdownVisibleChange={(e) => {
                          //console.log("FOCUS IS : ", e);
                          this.setState({ isStatusClicked: e });
                        }}
                        style={{
                          width: "100%",
                          boxShadow: "0px 5px 20px #1E1E2214",
                        }}
                        bordered={false}
                        onChange={(e) => {
                          //console.log("E IS: ", e);
                          this.handleStatus(e);
                        }}
                        menuItemSelectedIcon={
                          <RiCheckLine
                            style={{ color: Colors.Primary, height: "100%" }}
                          />
                        }
                      >
                        <Option value="">View All</Option>
                        <Option value="subscribed">
                          {" "}
                          <Blip /> Subscribed Users
                        </Option>
                        <Option value="basic">
                          {" "}
                          <Blip color={Colors.Secondary} /> Basic Users
                        </Option>
                      </Select>
                    </Col>
                  ) : (
                    <>
                      <Col xs={24} sm={24} md={4} lg={4} xl={4}></Col>
                      <Col xs={24} sm={24} md={10} lg={10} xl={10}>
                        <SearchBar
                          placeholder="Search here"
                          prefix={
                            <RiSearchLine style={{ color: Colors.Primary }} />
                          }
                          onChange={(e) => {
                            //console.log("E VALUE E is: ", e.target.value);
                            this.handleSearch(e.target.value);
                          }}
                        />
                      </Col>
                    </>
                  )}
                  <Col
                    xs={24}
                    sm={24}
                    md={userType == "eventmanager" ? 9 : 6}
                    lg={userType == "eventmanager" ? 9 : 6}
                    xl={userType == "eventmanager" ? 9 : 6}
                  >
                    <Select
                      suffixIcon={
                        !isUserTypeClicked ? (
                          <RiArrowDropDownLine
                            style={{ color: Colors.Primary, fontSize: "16px" }}
                          />
                        ) : (
                          <RiArrowDropUpLine
                            style={{ color: Colors.Primary, fontSize: "16px" }}
                          />
                        )
                      }
                      onDropdownVisibleChange={(e) => {
                        this.setState({ isUserTypeClicked: e });
                      }}
                      defaultValue="event manager"
                      style={{
                        width: "100%",
                        boxShadow: "0px 5px 20px #1E1E2214",
                      }}
                      bordered={false}
                      onChange={(e) => {
                        this.onUserTypeChange(e);
                      }}
                      menuItemSelectedIcon={
                        <RiCheckLine
                          style={{ color: Colors.Primary, height: "100%" }}
                        />
                      }
                    >
                      <Option disabled value="">
                        User Type
                      </Option>
                      <Option value="event manager">Event Manager</Option>
                      <Option value="rider">Rider</Option>
                      <Option value="judge">Judge</Option>
                      <Option value="judge/rider">Judge/Rider</Option>
                    </Select>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col span={24} style={{ padding: 30 }}>
            <Row
              style={{
                boxShadow: "0px 5px 20px #1E1E2214",
                borderRadius: "10px",
              }}
            >
              <UserTable
                userType={userType}
                userData={userData}
                limit={10}
                offset={offset}
                typee={userSelectType}
                like={like}
                userStatus={userStatus}
              />
            </Row>
          </Col>
          <Col span={24}>
            <Row justify="center">
              <Pagination
                responsive="true"
                showLessItems="true"
                current={current}
                pageSize={10}
                total={userCount ? userCount : 0}
                onChange={(e) => {
                  this.handlePages(e);
                }}
              />
            </Row>
          </Col>
        </Row>
      </HeaderComponent>
    );
  }
}

function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    getusers: (offset, limit, type, status, like) => {
      dispatch(UserAction.GetUsers(offset, limit, type, status, like));
    },
  };
}

export default withRouter(
  connect(
    (storeState) => ({
      userCount: storeState.UserReducer.usercount,
      userData: storeState.UserReducer.userdata,
    }),
    (mapStateToProps, mapDispatchToProps)
  )(Users)
);
