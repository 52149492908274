import {
  GETUSERSTATS,
  GETUSERSTATS_SUCCESS,
  GETUSERSTATS_FAILURE,
  GETUSERS,
  GETUSERS_SUCCESS,
  GETUSERS_FAILURE,
  GETUSERDETAIL,
  GETUSERDETAIL_SUCCESS,
  GETUSERDETAIL_FAILURE,
  GETAPPDOWNLOADCOUNT,
  GETAPPDOWNLOADCOUNT_SUCCESS,
  GETAPPDOWNLOADCOUNT_FAILURE,
  GETSUBSCRIBEDUSERCOUNT,
  GETSUBSCRIBEDUSERCOUNT_SUCCESS,
  GETSUBSCRIBEDUSERCOUNT_FAILURE,
  GETUSERTYPECOUNT,
  GETUSERTYPECOUNT_FAILURE,
  GETUSERTYPECOUNT_SUCCESS,
  GETEVENTSTATSCOUNT,
  GETEVENTSTATSCOUNT_FAILURE,
  GETEVENTSTATSCOUNT_SUCCESS,
  CLEARDASHBOARD,
  LOADER_TRUE,
  LOADER_FALSE,
} from "../../constants";

const initialState = {
  userstatistics: {},
  usercount: "",
  userdata: [],
  userdetails: {},
  appDownloadCounts: [],
  userTypeCounts: {},
  eventCounts: [],
  subscribedUserCount: [],
  userTableLoader: false,
  loader: false,
};
export default function UserReducer(state = initialState, action) {
  switch (action.type) {
    case GETUSERSTATS:
      state = {
        ...state,
        loader: false,
      };
      break;
    case GETUSERSTATS_SUCCESS: {
      if (action.payload) {
        state = {
          ...state,
          userstatistics: action?.payload,
          loader: true,
        };
      }
    }
    case GETUSERSTATS_FAILURE: {
      state = {
        ...state,
        loader: false,
      };
      break;
    }
    case GETUSERS:
      state = {
        ...state,
        userTableLoader: true,
      };
      break;
    case GETUSERS_SUCCESS: {
      if (action.payload) {
        state = {
          ...state,
          usercount: action.payload.totalUsers,
          userdata: action.payload.users,
          userTableLoader: false,
        };
      }
    }
    case GETUSERS_FAILURE: {
      state = {
        ...state,
        userTableLoader: false,
      };
      break;
    }
    case GETUSERDETAIL:
      state = {
        ...state,
        loader: false,
      };
      break;
    case GETUSERDETAIL_SUCCESS: {
      if (action.payload) {
        state = {
          ...state,
          userdetails: action.payload,
          loader: true,
        };
      }
    }
    case GETUSERDETAIL_FAILURE: {
      state = {
        ...state,
        loader: false,
      };
      break;
    }

    case GETAPPDOWNLOADCOUNT:
      state = {
        ...state,
        loader: false,
      };
      break;
    case GETAPPDOWNLOADCOUNT_SUCCESS: {
      if (action.payload) {
        const appData = action?.payload;
        let objKeys = Object.keys(appData);
        let objValues = Object.values(appData);
        let data = objKeys?.map((item) => {
          return {
            name: item.slice(0, 3),
          };
        });
        let newData = objValues.map((item, index) => {
          return {
            name: data[index].name,
            Ios: item,
            Android: item,
          };
        });

        state = {
          ...state,
          appDownloadCounts: newData,
          loader: true,
        };
      }
    }
    case GETAPPDOWNLOADCOUNT_FAILURE: {
      state = {
        ...state,
        loader: false,
      };
      break;
    }

    case GETSUBSCRIBEDUSERCOUNT:
      state = {
        ...state,
        loader: false,
      };
      break;
    case GETSUBSCRIBEDUSERCOUNT_SUCCESS: {
      if (action.payload) {
        const subscribedUserData = action?.payload?.subscribedUsersCount;
        let objKeys = Object.keys(subscribedUserData);
        let objValues = Object.values(subscribedUserData);
        let data = objKeys?.map((item) => {
          return {
            name: item.slice(0, 3),
          };
        });
        let newData = objValues.map((item, index) => {
          return {
            name: data[index].name,
            "Subscribed Users": item,
          };
        });
        state = {
          ...state,
          subscribedUserCount: newData,
          loader: true,
        };
      }
    }
    case GETSUBSCRIBEDUSERCOUNT_FAILURE: {
      state = {
        ...state,
        loader: false,
      };
      break;
    }

    case GETEVENTSTATSCOUNT:
      state = {
        ...state,
        loader: false,
      };
      break;
    case GETEVENTSTATSCOUNT_SUCCESS: {
      if (action.payload) {
        let completeData = Object.values(action?.payload?.eventCompletedCount);
        let createData = Object.values(action?.payload?.eventCreatedCount);
        let monthNames = Object.keys(action?.payload?.eventCreatedCount);
        let data = monthNames?.map((item) => {
          return {
            name: item.slice(0, 3),
          };
        });
        let newData = createData.map((item, index) => {
          return {
            name: data[index].name,
            "Event Creation": item,
          };
        });
        let combinedData = completeData.map((item, index) => {
          return {
            name: data[index].name,
            "Event Completion": item,
            "Event Creation": newData[index]["Event Creation"],
          };
        });
        state = {
          ...state,
          eventCounts: combinedData,
          loader: true,
        };
      }
    }
    case GETEVENTSTATSCOUNT_FAILURE: {
      state = {
        ...state,
        loader: false,
      };
      break;
    }

    case GETUSERTYPECOUNT:
      state = {
        ...state,
        loader: false,
      };
      break;
    case GETUSERTYPECOUNT_SUCCESS: {
      if (action.payload) {
        const newUserCount = [
          {
            name: "Rider",
            value: action?.payload?.rider,
          },
          {
            name: "Event Manager",
            value: action?.payload?.eventManager,
          },
          {
            name: "Judge",
            value: action?.payload?.judge,
          },
        ];
        state = {
          ...state,
          userTypeCounts: newUserCount,
          loader: true,
        };
      }
    }
    case GETUSERTYPECOUNT_FAILURE: {
      state = {
        ...state,
        loader: false,
      };
      break;
    }

    case CLEARDASHBOARD: {
      state = initialState;
    }
    default:
      break;
  }
  //console.log("STATE ISSS USER REDUCER", state, action, initialState)
  return state;
}
