import React, { useEffect, useState } from "react";
import { Modal, Row, Col } from "antd";
import { ButtonForm } from "../../components";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { EventAction } from "../../store/actions";
import { useDispatch } from "react-redux";
import { Colors, Images } from "../../config";
import Doc from "./DocService";
import moment from "moment";

const InvoiceModal = (props) => {
  const {
    isModalVisible,
    handleOk,
    handleCancel,
    content,
    deleteButtonLoader,
    invoiceData,
    invoiceDate,
    companyName
  } = props;
  console.log("invoiceData: ", invoiceData);
  const [invoiceDataa, setInvoiceDataa] = useState({});
  console.log(
    "invoiceDataa",
    invoiceData?.subscription_details?.items?.data.reduce((curr, prev) => {
      return (
        curr?.price?.unit_amount ? Number(prev?.price?.unit_amount) + Number(curr?.price?.unit_amount) : Number(prev?.price?.unit_amount)
      );
    }, 0)
  );
  const createPdf = (html) => Doc.createPdf(html);
  const bodyRef = React.createRef();
  return (
    <>
      <Modal
        visible={isModalVisible}
        {...props}
        onCancel={handleCancel}
        bodyStyle={{ height: "auto", padding: 0 }}
      >
        {/* <PdfContainer createPdf={createPdf}> */}
        <section className="pdf-body" ref={bodyRef}>
          <Col span={24}>
            <Row
              style={{
                backgroundColor: Colors.Blue,
                padding: "14px 20px 8px 20px",
              }}
            >
              <Col span={12}>
                <img src={Images.InvoiceIconNew} />
                <h1 style={{ color: Colors.White }}>Invoice</h1>
                <h3
                  style={{ color: Colors.White, cursor: "pointer" }}
                  onClick={() => {
                    createPdf(bodyRef.current);
                  }}
                >
                  <img src={Images.PdfIcon} height={30} width={30}/> Download Pdf
                </h3>
              </Col>
              <Col
                span={12}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <div>
                  <h1 style={{ color: Colors.White, textAlign: "right" }}>Event Pluz</h1>
                </div>
              </Col>
            </Row>
            <Row style={{ padding: "8px 20px 8px 20px", marginTop: "20px" }}>
              <Col span={24}>
                <h6>BILL TO:</h6>
                <h3>
                  {companyName ? companyName : "-"}
                </h3>
                <h6>INVOICE #</h6>
                  <h5>
                    {invoiceData.invoice_details
                      ? invoiceData.invoice_details.number
                      : "-"}
                  </h5>
                  <h5>DATE</h5>
                  <h5>
                    {moment(invoiceDate).format("MM/DD/YY")}
                  </h5>
              </Col>
            </Row>
            <Row style={{ padding: "8px 20px 8px 20px" }}>
              <Col
                span={24}
                style={{ borderTop: "2px solid black", overflowX: "auto" }}
              >
                <table width={"100%"}>
                  <tr>
                    <th style={{padding: "0px 5px 0px 0px"}}>ITEM</th>
                    <th style={{padding: "0px 5px 0px 5px"}}>DESCRIPTION</th>
                    <th style={{padding: "0px 5px 0px 5px"}}>QUANTITY</th>
                    <th style={{padding: "0px 5px 0px 5px"}}>PRICE</th>
                    <th style={{padding: "0px 5px 0px 5px"}}>TAX</th>
                    <th style={{padding: "0px 0px 0px 5px"}}>AMOUNT</th>
                  </tr>
                  {invoiceData?.invoice_details
                    ? invoiceData?.invoice_details?.lines?.data?.map((item) => {
                      console.log("ITEM: ", item);
                        return (
                          <tr>
                            <td style={{padding: "0px 5px 0px 0px"}}>{item?.plan?.amount == "9900" ? "Monthly Plan" : "Yearly Plan"}</td>
                            <td style={{padding: "0px 5px 0px 5px"}}>{item.description ? item.description : "-"}</td>
                            <td style={{padding: "0px 5px 0px 5px"}}>{item.quantity ? item.quantity : "-"}</td>
                            <td style={{padding: "0px 5px 0px 5px"}}>
                              {item.plan.amount ? item.plan.amount / 100 : "-"}
                            </td>
                            <td style={{padding: "0px 5px 0px 5px"}}>
                              {item.price.tax_rates
                                ? item.price.tax_rates
                                : "-"}
                            </td>
                            <td style={{padding: "0px 5px 0px 5px"}}>
                              {item.price.unit_amount
                                ? item.price.unit_amount / 100
                                : "-"}
                            </td>
                          </tr>
                        );
                      })
                    : invoiceData?.subscription_details?.items?.data?.map(
                        (item) => {
                          return (
                            <tr>
                              <td style={{padding: "0px 5px 0px 0px"}}>{item?.plan?.amount == "9900" ? "Monthly Plan" : "Yearly Plan"}</td>
                              <td style={{padding: "0px 5px 0px 5px"}}>
                                {item.plan.object ? item.plan.object : "-"}
                              </td >
                              <td>{item.quantity ? item.quantity : "-"}</td>
                              <td style={{padding: "0px 5px 0px 0px"}}>
                                {item.plan.amount
                                  ? item.plan.amount / 100
                                  : "-"}
                              </td>
                              <td style={{padding: "0px 5px 0px 0px"}}>
                                {item.price.tax_rates
                                  ? item.price.tax_rates
                                  : "-"}
                              </td>
                              <td style={{padding: "0px 5px 0px 0px"}}>
                                {item.price.unit_amount
                                  ? item.price.unit_amount / 100
                                  : "-"}
                              </td>
                            </tr>
                          );
                        }
                      )}
                </table>
              </Col>
            </Row>
            <Row style={{ marginTop: "15px" }}>
              <Col
                span={24}
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  backgroundColor: Colors.Blue,
                  padding: "15px 40px 7px 10px",
                }}
              >
                <div>
                  <h6 style={{ textAlign: "right", color: Colors.White }}>
                    TOTAL
                  </h6>
                  <h5 style={{ textAlign: "right", color: Colors.White }}>
                   {
                     invoiceData?.invoice_details ? 
                     invoiceData?.invoice_details?.lines?.data?.reduce((curr, prev) => {
                      return (
                        curr?.price?.unit_amount ? Number(prev?.price?.unit_amount/100) + Number(curr?.price?.unit_amount/100) : Number(prev?.price?.unit_amount/100)
                      );
                    }, 0) : invoiceData?.subscription_details?.items?.data.reduce((curr, prev) => {
                      return (
                        curr?.price?.unit_amount ? Number(prev?.price?.unit_amount/100) + Number(curr?.price?.unit_amount/100) : Number(prev?.price?.unit_amount/100)
                      );
                    }, 0)
                   }
                  </h5>
                </div>
              </Col>
            </Row>
          </Col>
        </section>
        {/* </PdfContainer> */}
      </Modal>
    </>
  );
};
export default React.memo(
  withRouter(
    connect((storeState) => ({
      deleteButtonLoader: storeState.EventReducer.deleteButtonLoader,
    }))(InvoiceModal)
  )
);
