import {
  GETTRANSACTIONS,
  GETTRANSACTIONS_SUCCESS,
  GETTRANSACTIONS_FAILURE,
  LOADER_FALSE,
  LOADER_TRUE,
} from "../../../store/constants";

export default class TransactionAction {
  static GetTransactions(offset, limit, like, status) {
    //console.log("PAYLOAD IS: ", limit, offset)
    return {
      type: GETTRANSACTIONS,
      offset, 
      limit, 
      like, 
      status
    };
  }
  static GetTransactionsSuccess(payload) {
    return {
      type: GETTRANSACTIONS_SUCCESS,
      payload,
    };
  }
  static GetTransactionsFailure() {
    return {
      type: GETTRANSACTIONS_FAILURE,
    };
  }
}
