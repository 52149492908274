import React, { useState } from "react";
import { Row, Col, Table, Space } from "antd";
import { StatusBar, InvoiceModal } from "../../components";
import moment from "moment";
import { RiDeleteBinLine } from "react-icons/ri";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { connect } from "react-redux";
import { LoadingOutlined } from "@ant-design/icons";
import { withRouter } from "react-router-dom";
import { EventAction } from "../../store/actions";
import { Images } from "../../config";
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const TransactionTable = (props) => {
  const { transactionData, limit, offset, like } = props;
  console.log("TRANSACTION DATA: ", transactionData);
  const history = useHistory();
  const dispatch = useDispatch();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [invoiceData, setInvoiceData] = useState([]);
  const [invoiceDate, setInvoiceDate] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [id, setId] = useState("");
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const columns = [
    {
      title: "",
      dataIndex: "thumbnailProfilePicture",
      key: "thumbnailProfilePicture",
      render: (text) => <img src={text} style={{ height: "3rem" }}></img>,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Transaction Id",
      dataIndex: "id",
      key: "id",
      render: (text, record) => <p>{text ? text : "-"}</p>,
    },
    {
      title: "Transaction File",
      dataIndex: "subscriptionPlanId",
      key: "subscriptionPlanId",
      render: (text, record) => (
        console.log("RECORD IS: ", text, record),
        <div onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}>
          <img src={Images.invoiceIcon} height={20} width={20} onClick={() => {setIsModalVisible(true);
          setInvoiceData(JSON.parse(record.receiptData));
          setInvoiceDate(record?.createdAt);
          setCompanyName(record?.Subscriber?.companyName);
          }}></img>
        </div>
      ),
    },
    {
      title: "Amount",
      dataIndex: "price",
      key: "price",
      render: (text, record) => <p>{text ? (text/100) : "-"}</p>,
    },
    // {
    //   title: "Status",
    //   dataIndex: "subscriptionStatus",
    //   key: "subscriptionStatus",
    //   render: (text, record) => (
    //     <Row justify="center">
    //       <StatusBar status={text} />
    //     </Row>
    //   ),
    // },
  ];
  const { transactionTableLoader } = props;
  return (
    <Col style={{ overflowX: "auto" }} span={24}>
      <Table
        style={{ cursor: "pointer" }}
        columns={columns}
        pagination={false}
        dataSource={transactionData}
        loading={{ spinning: transactionTableLoader, indicator: antIcon }}
        onRow={(record, rowIndex) => {
          console.log("record is: ", record);
          return {
            onClick: (event) => {
              history.push({
                pathname: `/users/user-details/${record.id}`,
                state: {
                  id: record.userId,
                  isTransaction: true
                },
              });
            },
          };
        }}
      />
      <InvoiceModal
        id={id}
        limit={limit}
        offset={offset}
        like={like}
        isModalVisible={isModalVisible}
        handleOk={handleOk}
        handleCancel={handleCancel}
        invoiceData={invoiceData}
        invoiceDate={invoiceDate}
        centered
        closable={false}
        footer={null}
        companyName={companyName}
        content={
          <p
            style={{ fontWeight: "bold", fontSize: "22px", marginLeft: "27px" }}
          >
            Are you sure you want to delete that event?
          </p>
        }
      />
    </Col>
  );
};
export default React.memo(
  withRouter(
    connect((storeState) => ({
      transactionTableLoader:
      storeState.TransactionReducer.transactionTableLoader,
    }))(TransactionTable)
  )
);
