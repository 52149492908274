import {
  GETTRANSACTIONS,
  GETTRANSACTIONS_SUCCESS,
  GETTRANSACTIONS_FAILURE,
  LOADER_TRUE,
  LOADER_FALSE,
} from "../../constants";

const initialState = {
  transactions: [],
  transactioncount: "",
  deleteButtonLoader: false,
  transactionTableLoader: false,
};

export default function EventReducer(state = initialState, action) {
  switch (action.type) {
    case GETTRANSACTIONS:
      state = {
        ...state,
        transactionTableLoader: true,
      };
      break;

    case GETTRANSACTIONS_SUCCESS: {
      if (action.payload) {
        console.log("ACTION PAYLOAD: ", action.payload);
        let transactionData = action.payload.subscriptions.rows;
        let transactionModified = transactionData.map((item) => {
          return {
            ...item,
            thumbnailProfilePicture: item.Subscriber.thumbnailProfilePicture,
            name: item.Subscriber.firstName + " " + item.Subscriber.lastName,
            email: item.Subscriber.email,
            subscriptionStatus: item.Subscriber.subscriptionStatus
          };
        });
        //console.log("EVENTS ARE EVENTS: ", action.payload)
        state = {
          ...state,
          transactioncount: action.payload.subscriptions.count,
          transactions: transactionModified,
          transactionTableLoader: false,
        };
      }
    }

    case GETTRANSACTIONS_FAILURE:
      state = {
        ...state,
        transactionTableLoader: false,
      };
      break;

    default:
      break;
  }
  return state;
}
