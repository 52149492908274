import React from "react";
import { connect } from "react-redux";
import { Row, Col } from "antd";
import { withRouter } from "react-router-dom";
import { Images } from "../../config";
import moment from "moment";
import {HeaderComponent, UserCard, ChartBox} from "../../components";
import { UserAction } from "../../store/actions";
import "../../index.css";

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount = () => {
    const {
      getuserstats,
      getappdownloadcount,
      getusertypecount,
      geteventstats,
      getsubscribedusercount,
    } = this.props;
    const endDate = new Date();
    const fourMonthsAgo = moment().subtract(4, "months");
    const startDate = new Date(fourMonthsAgo?.format());
    //console.log("FOUR MONTHS AGO: ", startDate);
    const startMonth = startDate.getMonth() + 1;
    const startYear = startDate.getFullYear();
    const endMonth = endDate.getMonth() + 1;
    const endYear = endDate.getFullYear();
    const startYearCase = startYear.toString().substr(-2);
    const endYearCase = endYear.toString().substr(-2);
    getuserstats();
    getappdownloadcount(startMonth, startYearCase, endMonth, endYearCase);
    geteventstats(startMonth, startYear, endMonth, endYear);
    getusertypecount();
    getsubscribedusercount(startMonth, startYearCase, endMonth, endYearCase);
  };
  componentWillUnmount = () => {
    const { cleardashboard } = this.props;
    cleardashboard();
  };
  render() {
    const {
      userStatistics,
      appDownloadCounts,
      userTypeCounts,
      eventCounts,
      subscribedUserCount,
    } = this.props;
    const { basicUsers, deletedUsers, subscribedUsers } = userStatistics;
    //console.log("userstats reducer is DATA: ", subscribedUserCount);
    return (
      <HeaderComponent>
        <Row gutter={[0, 40]} style={{ padding: 40 }}>
          <Col span={24}>
            <Row gutter={[20, 10]}>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <UserCard
                  userImage={Images.BasicUser}
                  userHeading="Basic Users"
                  userCount={basicUsers ? basicUsers : 0}
                />
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <UserCard
                  userImage={Images.SubbedUser}
                  userHeading="Subscribed Users"
                  userCount={subscribedUsers ? subscribedUsers : 0}
                />
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <UserCard
                  userImage={Images.DeletedUser}
                  userHeading="Deleted Users"
                  userCount={deletedUsers ? deletedUsers : 0}
                />
              </Col>
            </Row>
          </Col>
          <Col span={24} style={{ padding: 8, marginLeft: 10 }}>
            <Row gutter={[20, 0]}>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <ChartBox heading="App Downloads" data={appDownloadCounts} />
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <ChartBox
                  heading="User Types"
                  isNoDate={true}
                  newData={userTypeCounts}
                />
              </Col>
            </Row>
          </Col>
          <Col span={24} style={{ padding: 8, marginLeft: 10 }}>
            <Row gutter={[20, 0]}>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <ChartBox
                  heading="Events Against Time"
                  isEvent={true}
                  eventData={eventCounts}
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <ChartBox
                  heading="Subscription"
                  isSubscription={true}
                  subscribedData={subscribedUserCount}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </HeaderComponent>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getuserstats: () => {
      dispatch(UserAction.GetUserStats());
    },
    getappdownloadcount: (startMonth, startYear, endMonth, endYear) => {
      dispatch(
        UserAction.GetAppDownloadCount(startMonth, startYear, endMonth, endYear)
      );
    },
    getusertypecount: () => {
      dispatch(UserAction.GetUserTypeCount());
    },
    geteventstats: (startMonth, startYear, endMonth, endYear) => {
      dispatch(
        UserAction.GetEventStats(startMonth, startYear, endMonth, endYear)
      );
    },
    getsubscribedusercount: (startMonth, startYear, endMonth, endYear) => {
      dispatch(
        UserAction.GetSubscribedUserCount(
          startMonth,
          startYear,
          endMonth,
          endYear
        )
      );
    },
    cleardashboard: () => {
      dispatch(UserAction.ClearDashboard());
    },
  };
}

export default withRouter(
  connect(
    (storeState) => ({
      userStatistics: storeState.UserReducer.userstatistics,
      appDownloadCounts: storeState.UserReducer.appDownloadCounts,
      userTypeCounts: storeState.UserReducer.userTypeCounts,
      eventCounts: storeState.UserReducer.eventCounts,
      subscribedUserCount: storeState.UserReducer.subscribedUserCount,
    }),
    (mapDispatchToProps)
  )(Dashboard)
);
