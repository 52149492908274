import React from "react";
import { Layout, Row, Col } from "antd";
import Image from "../image";
import { Link } from "react-router-dom";
import { Colors, Images } from "../../config";
import "../../index.css";
const { Sider } = Layout;
const NavigationBar = () => {
  const pathName = window.location.pathname;
  //console.log("pathname is:", pathName.includes("/event_pluz_admin"));
  const isUser = pathName.includes("users");
  const isForm = pathName.includes("forms");
  const isEvent = pathName.includes("events");
  const isTrans = pathName.includes("transactions");
  const isDashboard = !isUser && !isForm && !isEvent && !isTrans;
  return (
    <>
      <Sider
        style={{
          backgroundColor: Colors.White,
          boxShadow: "0px 5px 20px #1E1E220C",
          minHeight: "100vh",
        }}
        width="90"
      >
        <Col span={24}>
          <Row gutter={[0, 120]} justify="center">
            <Col span={18} style={{ marginTop: "16px" }}>
              <Image src={Images.Logo}></Image>
            </Col>
            <Col span={24}>
              <Row gutter={[0, 35]}>
                <Col span={24}>
                  <Link to="/">
                    <Image
                      src={
                        isDashboard
                          ? Images.DashboardIconClicked
                          : Images.DashboardIcon
                      }
                      style={{ cursor: "pointer" }}
                    ></Image>
                  </Link>
                </Col>
                <Col span={24}>
                  <Link to="/users">
                    <Image
                      src={isUser ? Images.UserOnClick : Images.UserIcon}
                      style={{ cursor: "pointer" }}
                    ></Image>
                  </Link>
                </Col>
                <Col span={24}>
                  <Link to="/events">
                    <Image
                      src={
                        isEvent
                          ? Images.EventIconSiderClicked
                          : Images.EventsIcon
                      }
                      style={{ cursor: "pointer" }}
                    ></Image>
                  </Link>
                </Col>
                <Col span={24}>
                  <Link to="/forms">
                    <Image
                      src={isForm ? Images.FormClicked : Images.FormsIcon}
                      style={{ cursor: "pointer" }}
                    ></Image>
                  </Link>
                </Col>
                <Col span={24}>
                  <Link to="/transactions">
                    <Image
                      src={
                        isTrans
                          ? Images.TransactionIconSiderClicked
                          : Images.TransactionsIcon
                      }
                      style={{ cursor: "pointer" }}
                    ></Image>
                  </Link>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Sider>
    </>
  );
};

export default React.memo(NavigationBar);
