import {
  GETUSERS,
  GETUSERS_SUCCESS,
  GETUSERS_FAILURE,
  GETUSERDETAIL,
  GETUSERDETAIL_SUCCESS,
  GETUSERDETAIL_FAILURE,
  USERSTATUSUPDATE,
  USERSTATUSUPDATE_SUCCESS,
  USERSTATUSUPDATE_FAILURE,
  GETUSERSTATS,
  GETUSERSTATS_SUCCESS,
  GETUSERSTATS_FAILURE,
  GETAPPDOWNLOADCOUNT,
  GETAPPDOWNLOADCOUNT_SUCCESS,
  GETAPPDOWNLOADCOUNT_FAILURE,
  GETUSERTYPECOUNT,
  GETUSERTYPECOUNT_SUCCESS,
  GETUSERTYPECOUNT_FAILURE,
  GETSUBSCRIBEDUSERCOUNT,
  GETSUBSCRIBEDUSERCOUNT_SUCCESS,
  GETSUBSCRIBEDUSERCOUNT_FAILURE,
  GETEVENTSTATSCOUNT,
  GETEVENTSTATSCOUNT_SUCCESS,
  GETEVENTSTATSCOUNT_FAILURE,
  CLEARDASHBOARD,
  LOADER_FALSE,
  LOADER_TRUE,
} from "../../../store/constants";

export default class UserAction {
  static GetUsers(limit, offset, typee, status, like) {
    //console.log("PAYLOAD IS: ", limit, offset)
    return {
      type: GETUSERS,
      limit,
      offset,
      typee,
      status,
      like,
    };
  }
  static GetUsersSuccess(payload) {
    return {
      type: GETUSERS_SUCCESS,
      payload,
    };
  }
  static GetUsersFailure() {
    return {
      type: GETUSERS_FAILURE,
    };
  }

  static GetUserDetails(id) {
    ////console.log("PAYLOAD IS: ", limit, offset)
    return {
      type: GETUSERDETAIL,
      id,
    };
  }
  static GetUserDetailsSuccess(payload) {
    return {
      type: GETUSERDETAIL_SUCCESS,
      payload,
    };
  }
  static GetUserDetailsFailure() {
    return {
      type: GETUSERDETAIL_FAILURE,
    };
  }

  static GetUserTypeCount() {
    //console.log("PAYLOAD IS USER TYPE: ")
    return {
      type: GETUSERTYPECOUNT,
    };
  }
  static GetUserTypeCountSuccess(payload) {
    return {
      type: GETUSERTYPECOUNT_SUCCESS,
      payload,
    };
  }
  static GetUserTypeCountFailure() {
    return {
      type: GETUSERTYPECOUNT_FAILURE,
    };
  }

  static GetSubscribedUserCount(startMonth, startYear, endMonth, endYear) {
    //console.log("PAYLOAD IS STATS: ");
    return {
      type: GETSUBSCRIBEDUSERCOUNT,
      startMonth,
      startYear,
      endMonth,
      endYear,
    };
  }
  static GetSubscribedUserCountSuccess(payload) {
    return {
      type: GETSUBSCRIBEDUSERCOUNT_SUCCESS,
      payload,
    };
  }
  static GetSubscribedUserCountFailure() {
    return {
      type: GETSUBSCRIBEDUSERCOUNT_FAILURE,
    };
  }
  static UpdateUserStatus(limit, offset, typee, id, status, like, userStatus) {
    //console.log("USER STATUS UPDATE", userStatus);
    return {
      type: USERSTATUSUPDATE,
      limit,
      offset,
      typee,
      id,
      status,
      like,
      userStatus,
    };
  }
  static UpdateUserStatusSuccess(payload) {
    return {
      type: USERSTATUSUPDATE_SUCCESS,
      payload,
    };
  }
  static UpdateUserStatusFailure() {
    return {
      type: USERSTATUSUPDATE_FAILURE,
    };
  }

  static GetUserStats() {
    //console.log("PAYLOAD IS STATS: ");
    return {
      type: GETUSERSTATS,
    };
  }
  static GetUserStatsSuccess(payload) {
    return {
      type: GETUSERSTATS_SUCCESS,
      payload,
    };
  }
  static GetUserStatsFailure() {
    return {
      type: GETUSERSTATS_FAILURE,
    };
  }

  static GetEventStats(startMonth, startYear, endMonth, endYear) {
    //console.log("PAYLOAD IS STATS: ");
    return {
      type: GETEVENTSTATSCOUNT,
      startMonth,
      startYear,
      endMonth,
      endYear,
    };
  }
  static GetEventStatsSuccess(payload) {
    return {
      type: GETEVENTSTATSCOUNT_SUCCESS,
      payload,
    };
  }
  static GetEventStatsFailure() {
    return {
      type: GETEVENTSTATSCOUNT_FAILURE,
    };
  }

  static GetAppDownloadCount(startMonth, startYear, endMonth, endYear) {
    //console.log("PAYLOAD IS STATS: ");
    return {
      type: GETAPPDOWNLOADCOUNT,
      startMonth,
      startYear,
      endMonth,
      endYear,
    };
  }
  static GetAppDownloadCountSuccess(payload) {
    return {
      type: GETAPPDOWNLOADCOUNT_SUCCESS,
      payload,
    };
  }
  static GetAppDownloadCountFailure() {
    return {
      type: GETAPPDOWNLOADCOUNT_FAILURE,
    };
  }

  static ClearDashboard() {
    return {
      type: CLEARDASHBOARD,
    };
  }

  static LoaderTrue() {
    return {
      type: LOADER_TRUE,
    };
  }

  static LoaderFalse() {
    return {
      type: LOADER_FALSE,
    };
  }
}
