import React, { Component } from "react";
import { LoginBox, ButtonForm } from "../../components";
import { connect } from "react-redux";
import { Row, Col, Input } from "antd";
import { Formik, Form } from "formik";
import { AuthAction } from "../../store/actions";
import { withRouter } from "react-router-dom";
import { Colors, Schemas } from "../../config";
import { RiEyeLine, RiEyeCloseLine } from "react-icons/ri";
class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      confirm_password: "",
      isType: false,
      isNewPassType: false,
      isConfirmType: false,
    };
  }
  render() {
    const { changepassword, changePasswordLoader, history } = this.props;
    const { isType, isNewPassType, isConfirmType } = this.state;
    return (
      <LoginBox isDontShow={true} isChangePassword={true}>
        <Col span={24} style={{ marginTop: "20px" }}>
          <h3>
            <b>Reset your Password</b>
          </h3>
          <p>
            Choose a strong new password.
            <br /> Make sure it's unique.
          </p>
        </Col>
        <Formik
          initialValues={{
            oldPassword: "",
            password: "",
            confirm_password: "",
          }}
          validationSchema={Schemas.changePasswordValidations}
          onSubmit={(values, { resetForm }) => {
            //console.log("these are values forget password: ", values);
            let confirm_password;
            ({ confirm_password, ...values } = values);
            console.log("VALUES ARE: ", values);
            changepassword(values, (isAccepted) => {
              //console.log("IS SIGNED IN ? ", isAccepted);
              isAccepted
                ? history.push({
                    pathname: "/",
                  })
                : console.log("IS FALSE", isAccepted);
            });
            resetForm({ values: "" });
          }}
          render={({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <Form>
              <Row
                style={{
                  justifyContent: "center",
                }}
                gutter={[0, 20]}
              >
                <Col span={16}>
                  <Input
                    type={!isType ? "password" : "text"}
                    placeholder="Old Password"
                    name="oldPassword"
                    value={values.oldPassword}
                    onChange={handleChange}
                    suffix={
                      !isType ? (
                        <RiEyeCloseLine
                          onClick={() => {
                            this.setState({ isType: !isType });
                          }}
                        />
                      ) : (
                        <RiEyeLine
                          onClick={() => {
                            this.setState({ isType: !isType });
                          }}
                        />
                      )
                    }
                    // iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                  />
                  <label style={{ color: Colors.Red }}>
                    {errors.oldPassword &&
                      touched.oldPassword &&
                      errors.oldPassword}
                  </label>
                </Col>
                <Col span={16}>
                  <Input
                    type={!isNewPassType ? "password" : "text"}
                    placeholder="New Password"
                    name="password"
                    value={values.password}
                    onChange={handleChange}
                    suffix={
                      !isNewPassType ? (
                        <RiEyeCloseLine
                          onClick={() => {
                            this.setState({
                              isNewPassType: !isNewPassType,
                            });
                          }}
                        />
                      ) : (
                        <RiEyeLine
                          onClick={() => {
                            this.setState({
                              isNewPassType: !isNewPassType,
                            });
                          }}
                        />
                      )
                    }
                    // iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                  />
                  <label style={{ color: Colors.Red }}>
                    {errors.password && touched.password && errors.password}
                  </label>
                </Col>
                <Col span={16}>
                  <Input
                    type={!isConfirmType ? "password" : "text"}
                    placeholder="Confirm Password"
                    name="confirm_password"
                    value={values.confirm_password}
                    onChange={handleChange}
                    suffix={
                      !isConfirmType ? (
                        <RiEyeCloseLine
                          onClick={() => {
                            this.setState({
                              isConfirmType: !isConfirmType,
                            });
                          }}
                        />
                      ) : (
                        <RiEyeLine
                          onClick={() => {
                            this.setState({
                              isConfirmType: !isConfirmType,
                            });
                          }}
                        />
                      )
                    }
                    // iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                  />
                  <label style={{ color: Colors.Red }}>
                    {errors.confirm_password &&
                      touched.confirm_password &&
                      errors.confirm_password}
                  </label>
                </Col>
                <Col span={24}>
                  {/* <Link to="">
                      <label style={{ cursor: "pointer", color: "#08ac9c" }}>
                        Forgot Password?
                      </label>
                    </Link> */}
                </Col>
                <Col span={24}>
                  <ButtonForm loading={changePasswordLoader} htmlType="submit">
                    Continue
                  </ButtonForm>
                </Col>
              </Row>
            </Form>
          )}
        />
      </LoginBox>
    );
  }
}

function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    changepassword: (payload, cb) => {
      dispatch(AuthAction.ChangePassword(payload, cb));
    },
  };
}
export default withRouter(
  connect(
    (storeState) => ({
      changePasswordLoader: storeState?.AuthReducer?.changePasswordLoader,
    }),
    (mapStateToProps, mapDispatchToProps)
  )(ChangePassword)
);
