import React from "react";
import Image from "../image";
import { Col, Card } from "antd";
import useMedia from "use-media";

const UserCard = (props) => {
  const { userImage, userHeading, userCount } = props;
  const isWide = useMedia({ minWidth: "284px" });
  return (
    <Col span={24} className="site-card-border-less-wrapper">
      <Card
        style={{ boxShadow: "0px 5px 20px #1E1E2214", borderRadius: "10px" }}
        bordered="false"
      >
        <div style={{ float: "left" }}>
          <Image src={userImage} style={{ float: "left" }}></Image>
          <h1
            style={{
              fontFamily: "Montserrat, sans-serif",
              marginTop: "70px",
              fontSize: isWide ? "15px" : "13px"
            }}
          >
            <b>{userHeading}</b>
            <br />
          </h1>
          <label
            style={{
              fontFamily: "normal normal regular 16px/19px Montserrat",
              opacity: "1",
              float: "left",
              color: "#51515B",
            }}
          >
            {userCount}
          </label>
        </div>
      </Card>
    </Col>
  );
};

export default React.memo(UserCard);
