import {
  GETEVENTS,
  GETEVENTS_SUCCESS,
  GETEVENTS_FAILURE,
  DELETEEVENTS,
  DELETEEVENTS_SUCCESS,
  DELETEEVENTS_FAILURE,
  LOADER_TRUE,
  LOADER_FALSE,
} from "../../constants";

const initialState = {
  events: [],
  eventcount: "",
  deleteButtonLoader: false,
  eventTableLoader: false,
};

export default function EventReducer(state = initialState, action) {
  switch (action.type) {
    case GETEVENTS:
      state = {
        ...state,
        eventTableLoader: true,
      };
      break;

    case GETEVENTS_SUCCESS: {
      if (action.payload) {
        // console.log("EVENTS ARE EVENTS: ", action.payload)
        let eventData = action?.payload?.events?.rows;
        let eventModified = eventData.map((item) => {
          return {
            ...item,
            eventManagerName:
              item?.EventManager ? item?.EventManager?.firstName + " " + item?.EventManager?.lastName : null,
          };
        });
        state = {
          ...state,
          eventcount: action?.payload?.events?.count,
          events: eventModified,
          eventTableLoader: false,
        };
      }
    }

    case GETEVENTS_FAILURE:
      state = {
        ...state,
        eventTableLoader: false,
      };
      break;

    case DELETEEVENTS:
      state = {
        ...state,
        deleteButtonLoader: true,
      };
      break;

    case DELETEEVENTS_SUCCESS: {
      if (action.payload) {
        state = {
          ...state,
          deleteButtonLoader: false,
        };
      }
    }

    case DELETEEVENTS_FAILURE:
      state = {
        ...state,
        deleteButtonLoader: false,
      };
      break;

    default:
      break;
  }
  return state;
}
