import Avatar from "../../assets/images/User.svg";
import DashboardIcon from "../../assets/images/dashboard-line.svg";
import UserIcon from "../../assets/images/Userlist.svg";
import EventsIcon from "../../assets/images/Events.svg";
import EventIconClicked from "../../assets/images/EventIconClicked.svg";
import TransactionsIcon from "../../assets/images/transaction.svg";
import DashboardIconClicked from "../../assets/images/dashboardiconclicked.svg";
import UserIconClicked from "../../assets/images/userafterclick.svg";
import FormIconClicked from "../../assets/images/formafterclick.svg";
import UserOnClick from "../../assets/images/useronclick.svg";
import FormClicked from "../../assets/images/formsonclick.svg";
import FormsIcon from "../../assets/images/forms.svg";
import DashboardIconAfterClicked from "../../assets/images/dashboardafterclick.svg";
import Logo from "../../assets/images/Logo.svg";
import BasicUser from "../../assets/images/basicuser.svg";
import SubbedUser from "../../assets/images/subbeduser.svg";
import DeletedUser from "../../assets/images/deleteduser.svg";
import LeftArrow from "../../assets/images/left-arrow.png";
import EventIconSiderClicked from "../../assets/images/EventIconSiderClicked.svg";
import TransactionIconSiderClicked from "../../assets/images/TransactionIconSiderClicked.svg";
import TransactionIconFooter from "../../assets/images/TransactionIconFooter.svg";
import invoiceIcon from "../../assets/images/invoiceIcon.svg";
import InvoiceIconNew from "../../assets/images/invoiceiconnew.svg";
import PdfIcon from "../../assets/images/pdfIcon.png";
const Images = {
  Avatar,
  DashboardIcon,
  UserIcon,
  EventsIcon,
  TransactionsIcon,
  DashboardIconClicked,
  UserIconClicked,
  FormIconClicked,
  UserOnClick,
  FormClicked,
  FormsIcon,
  DashboardIconAfterClicked,
  Logo,
  BasicUser,
  SubbedUser,
  DeletedUser,
  LeftArrow,
  EventIconClicked,
  EventIconSiderClicked,
  TransactionIconSiderClicked,
  TransactionIconFooter,
  invoiceIcon,
  InvoiceIconNew,
  PdfIcon
}

export default Images;