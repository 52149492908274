import React from "react";
import { connect } from "react-redux";
import { Row, Col, Pagination } from "antd";
import { Blip, SearchBar, HeaderComponent, EventTable } from "../../components";
import { withRouter } from "react-router-dom";
import { Colors } from "../../config";
import { EventAction } from "../../store/actions";
import { RiSearchLine } from "react-icons/ri";
import "../../index.css";
class Events extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
      like: "",
      current: 1,
    };
  }
  handleSearch = (val) => {
    const { getevents } = this.props;
    getevents(val, 10, 0);
    this.setState({ like: val, current: 1, offset: 0  });
  };
  componentDidMount() {
    //console.log("I AM MOUNTED");
    const { getevents } = this.props;
    getevents("", 10, 0);
  }

  handlePages = (pageNumber) => {
    const limit = 10;
    let offset = (pageNumber - 1) * limit;
    const { getevents } = this.props;
    const { like } = this.state;
    getevents(like, limit, offset);
    this.setState({ offset: offset, current: pageNumber });
  };

  render() {
    const { eventCount, events } = this.props;
    const { offset, like, current } = this.state;
    console.log("OFFSET UPDATE IS: ", eventCount, events);
    return (
      <HeaderComponent>
        <Row gutter={[0, 20]} style={{ padding: 40 }}>
          <Col span={24}>
            <Row gutter={[0, 10]}>
              <Col xs={24} sm={24} md={14} lg={14} xl={14} align="start">
                <Row>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <h1>
                      <b>{"Events   "}</b>
                      <Blip /> {eventCount ? eventCount : 0}
                    </h1>
                  </Col>
                </Row>
              </Col>
              <Col xs={24} sm={24} md={10} lg={10} xl={10}>
                <Row justify="center" gutter={[15, 10]}>
                  <Col xs={24} sm={24} md={9} lg={9} xl={9}></Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <SearchBar
                      placeholder="Search here"
                      prefix={
                        <RiSearchLine style={{ color: Colors.Primary }} />
                      }
                      onChange={(e) => {
                        //console.log("E VALUE E is: ", e.target.value);
                        this.handleSearch(e.target.value);
                      }}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col span={24} style={{ padding: 30 }}>
            <Row
              style={{
                boxShadow: "0px 5px 20px #1E1E2214",
                borderRadius: "10px",
              }}
            >
              <EventTable
                eventData={events}
                limit={10}
                offset={offset}
                like={like}
              />
            </Row>
          </Col>
          <Col span={24}>
            <Row justify="center">
              <Pagination
                responsive="true"
                showLessItems="true"
                current={current}
                pageSize={10}
                total={eventCount ? eventCount : 0}
                onChange={(e) => {
                  //console.log("Page number is:", e);
                  this.handlePages(e);
                }}
              />
            </Row>
          </Col>
        </Row>
      </HeaderComponent>
    );
  }
}

function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    getevents: (like, limit, offset) => {
      dispatch(EventAction.GetEvents(like, limit, offset));
    },
  };
}

export default withRouter(
  connect(
    (storeState) => ({
      eventCount: storeState.EventReducer.eventcount,
      events: storeState.EventReducer.events,
    }),
    (mapStateToProps, mapDispatchToProps)
  )(Events)
);
