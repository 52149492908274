import React from 'react';
import styled from "styled-components";
import {Button} from 'antd';
import {Colors} from "../../config"

const StyledButton = styled(Button)`
  background-color: ${Colors.Primary};
  color: ${Colors.White};
  cursor: pointer;
  border-radius: 10px;
  width: 11rem;
  height: 2.5rem;
  &:hover, &:active, &:focus {
    background-color: ${Colors.Primary};
    color: ${Colors.White};
  }
`;

const ButtonForm = (props) => {
  return <StyledButton {...props}></StyledButton>;
}

export default React.memo(ButtonForm)