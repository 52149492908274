import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
// import { StripeProvider } from 'react-stripe-elements';
import store from "./store";
import './index.css';
//import * as serviceWorker from './serviceWorker';
import 'antd/dist/antd.css';
ReactDOM.render(
    <BrowserRouter basename="/">
      <Provider store={store}>
        <App />
      </Provider>
    </BrowserRouter>,
document.getElementById('root'));
