import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Colors, Schemas } from "../../config";
import { LoginBox, ButtonForm } from "../../components";
import { Row, Col, Input } from "antd";
import { AuthAction } from "../../store/actions";
import { RiEyeLine, RiEyeCloseLine } from "react-icons/ri";
import { Formik, Form } from "formik";
class SignIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      validEmail: true,
      validPass: true,
      isType: false,
    };
  }

  render() {
    const { signin, signInLoader, history } = this.props;
    const { isType } = this.state;
    return (
      <LoginBox isLogo={true}>
        <Col span={24} style={{ marginTop: "20px" }}>
          <h3>
            <b>Welcome</b>
          </h3>
          <p>Please Login to Continue</p>
        </Col>
        <Col span={24} align="center" style={{ marginBottom: "15px" }}>
          <Formik
            initialValues={{
              email: "",
              password: "",
            }}
            validationSchema={Schemas.loginValidations}
            onSubmit={(values, { resetForm }) => {
              values = {
                ...values,
                deviceType: "admin",
              };
              signin(values, (isSignedIn) => {
                isSignedIn
                  ? history.push("/")
                  : history.push("/login");
              });
              resetForm({ values: "" });
            }}
            render={({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <Form>
                <Row
                  style={{
                    justifyContent: "center",
                  }}
                  gutter={[0, 20]}
                >
                  <Col span={16}>
                    <Input
                      type="text"
                      placeholder="Email"
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                    ></Input>
                    <label style={{ color: Colors.Red }}>
                      {errors.email && touched.email && errors.email}
                    </label>
                  </Col>
                  <Col span={16}>
                    <Input
                      type={!isType ? "password" : "text"}
                      placeholder="Password"
                      name="password"
                      value={values.password}
                      suffix={
                        !isType ? (
                          <RiEyeCloseLine
                            onClick={() => {
                              this.setState({ isType: !isType });
                            }}
                          />
                        ) : (
                          <RiEyeLine
                            onClick={() => {
                              this.setState({ isType: !isType });
                            }}
                          />
                        )
                      }
                      onChange={handleChange}
                    />
                    <label style={{ color: Colors.Red }}>
                      {errors.password && touched.password && errors.password}
                    </label>
                  </Col>
                  <Col span={16}>
                    <label
                      style={{
                        cursor: "pointer",
                        color: Colors.Primary,
                        float: "left",
                        opacity: "1",
                        font: "normal normal bold 12px/14px Poppins",
                      }}
                      onClick={() => {
                        history.push({
                          pathname: "/forget-password",
                        });
                      }}
                    >
                      Forgot Password?
                    </label>
                  </Col>
                  <Col span={24}>
                    <ButtonForm loading={signInLoader} htmlType="submit">
                      Login
                    </ButtonForm>
                  </Col>
                </Row>
              </Form>
            )}
          />
        </Col>
      </LoginBox>
    );
  }
}

function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    signin: (payload, cb) => {
      dispatch(AuthAction.SignIn(payload, cb));
    },
  };
}

export default withRouter(
  connect(
    (storeState) => ({ signInLoader: storeState.AuthReducer.signInLoader }),
    (mapStateToProps, mapDispatchToProps)
  )(SignIn)
);
