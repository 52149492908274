import React from "react";
import { connect } from "react-redux";
import { Row, Col, Pagination, Button, Spin } from "antd";
import { Blip, HeaderComponent, FormTable } from "../../components";
import { withRouter } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import { Colors } from "../../config";
import { AuthAction } from "../../store/actions";
import { FormAction } from "../../store/actions";
import "../../index.css";
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
class Forms extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      forms: [],
      user: this?.props?.user ? this?.props?.user : "",
      userAuth: {},
      fileInputRef: "",
      fileName: "",
      file: "",
      limit: 5,
      offset: "",
    };
  }
  handleLogout = () => {
    const { Signout } = this.props;
    Signout();
  };

  componentDidMount() {
    const { getforms } = this.props;
    getforms(5, 0);
  }

  handleForms = (pageNumber) => {
    const limit = 5;
    let offset = (pageNumber - 1) * limit;
    const { getforms } = this.props;
    getforms(limit, offset);
    this.setState({ offset: offset });
  };

  handleUpload = () => {
    const { uploadform } = this.props;
    const file = this.state.file;
    const limit = 5;
    const offset = this.state.offset;
    uploadform({ file }, limit, offset);
    this.setState({ fileName: "", file: "" });
  };
  render() {
    const { Signout, forms, uploadFormLoader, formCount, getFormLoader } =
      this.props;
    const file = this.state.file;
    const userName = this.state.user.firstName + " " + this.state.user.lastName;
    let fileInputRef;
    const offset = this.state.offset;
    const limit = this.state.limit;
    return (
      <HeaderComponent>
        <Row style={{ padding: 11 }}>
          <Col xs={24} md={12}>
            <Row gutter={[0, 15]}>
              <Col span={17}>
                <Row>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <h1>
                      <b>Forms </b>
                      <Blip />{" "}
                      {this?.props?.formCount ? this?.props?.formCount : 0}
                    </h1>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <p>
                      Upload Form in csv format <br />
                    </p>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <input
                      type="file"
                      onClick={(event) => {
                        event.target.value = null;
                      }}
                      style={{ display: "none" }}
                      ref={(ref) => (this.state.fileInputRef = ref)}
                      onChange={(e) => {
                        var files = e?.target?.files;
                        this.setState({
                          fileName: files[0]?.name,
                          file: files[0],
                        });
                      }}
                    />
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Row gutter={[10, 0]} justify="center">
                      <Col>
                        <Button
                          onClick={() => this.state.fileInputRef.click()}
                          style={{
                            boxShadow: `inset 0px 0px 0px 2px ${Colors.Primary}`,
                          }}
                        >
                          Browse{" "}
                        </Button>
                        <div style={{ width: "100px" }}>
                          <p
                            style={{
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {this.state.fileName}
                          </p>
                        </div>
                      </Col>

                      <Col>
                        <Button
                          disabled={!file}
                          loading={uploadFormLoader}
                          onClick={this.handleUpload}
                          style={{
                            backgroundColor: Colors.Primary,
                            color: Colors.White,
                          }}
                        >
                          Upload
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col span={17} className="formColumn">
                <Row gutter={[0, 14]}>
                  {!forms && !forms?.length ? (
                    <Col xs={24} sm={24} md={17} lg={17} xl={17}>
                      <h3 className="textCenter"> No Forms Exist</h3>
                    </Col>
                  ) : getFormLoader ? (
                    <Col
                      xs={24}
                      sm={24}
                      md={24}
                      lg={24}
                      xl={24}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "18.8rem",
                      }}
                    >
                      <Spin indicator={antIcon} spinning={getFormLoader} />
                    </Col>
                  ) : (
                    <>
                      {forms?.map((form, index) => (
                        <Col
                          xs={24}
                          sm={24}
                          md={24}
                          lg={24}
                          xl={24}
                          offset={index && 1}
                          pull={index && 1}
                        >
                          <FormTable
                            id={form.id}
                            fileName={form.name}
                            URL={form.url}
                            offset={offset}
                            limit={limit}
                          />
                        </Col>
                      ))}
                    </>
                  )}
                </Row>
              </Col>
              {this?.props?.formCount == 0 ? null : (
                <Col xs={17} md={19}>
                  <Row>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <Pagination
                        responsive="true"
                        showLessItems="true"
                        defaultCurrent={1}
                        pageSize={5}
                        total={
                          this?.props?.formCount ? this?.props?.formCount : 0
                        }
                        onChange={(e) => {
                          //console.log("Page number is:" , e);
                          this.handleForms(e);
                        }}
                      />
                    </Col>
                  </Row>
                </Col>
              )}
            </Row>
          </Col>
        </Row>
      </HeaderComponent>
    );
  }
}

function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    Signout: () => {
      dispatch(AuthAction.Signout());
    },
    getforms: (payload, limit, offset) => {
      dispatch(FormAction.GetForms(payload, limit, offset));
    },
    uploadform: (payload, limit, offset) => {
      dispatch(FormAction.UploadForms(payload, limit, offset));
    },
  };
}

export default withRouter(
  connect(
    (storeState) => ({
      user: storeState.AuthReducer.user,
      forms: storeState.FormReducer.tests,
      formCount: storeState.FormReducer.totaltests,
      uploadFormLoader: storeState.FormReducer.loader,
      getFormLoader: storeState.FormReducer.getFormLoader,
    }),
    (mapStateToProps, mapDispatchToProps)
  )(Forms)
);
