import React from "react";
import { connect } from "react-redux";
import {
  Row,
  Col,
  Input
} from "antd";
import {Image, HeaderComponent} from "../../components";
import {withRouter} from "react-router-dom";
import {Images} from "../../config"
import { UserAction } from "../../store/actions";
import "../../index.css";
class UsersDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isCondition: false,
      userId: ""
    };
  }
  componentDidMount() {
    //console.log("PROPS USER DETAILS ARE: ", this.props);
    const {getuserdetails, history} = this.props;
    if(this?.props?.location?.state?.id) {
      let userId = this?.props?.location?.state?.id;
      getuserdetails(userId);
    }
    else {
      history.push("/")
    }
  }
  render() {
    const {userDetails, history} = this.props;
    //console.log("user details are: ", userDetails);
    return (
      <HeaderComponent>
          <Row gutter={[0, 20]} style={{padding: 60}}>
            <Col span={24}>
              <Row justify="center">
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Row>
                    <Col span={2}></Col>
                    <Col xs={2} sm={4} md={2} lg={2} xl={2}>
                      <Image src={Images.LeftArrow} style={{ cursor: "pointer" }} onClick={() => this?.props?.location?.state?.isTransaction ? history.push("/transactions") : history.push("/users")}></Image>
                    </Col>
                    <Col xs={20} sm={18} md={14} lg={8} xl={8}>
                      <h1
                        style={{
                          fontFamily: "Montserrat, sans-serif",
                          fontSize: "24px",
                        }}
                      >
                        <b>User Details </b>
                      </h1>
                    </Col>
                  </Row>
                </Col>
                <Col span={12}></Col>
              </Row>
            </Col>
            <Col span={21}>
              <Row justify="center" gutter={[40, 20]}>
                <Col xs={24} sm={24} md={9} lg={9} xl={9} style={{paddingRight: "0px"}}>
                  <h1 style={{ float: "left" }}>First Name</h1>
                  <Input style={{borderRadius: "5px"}} readOnly={true} value={userDetails?.firstName ? userDetails?.firstName : "-"}/>
                </Col>
                <Col xs={24} sm={24} md={9} lg={9} xl={9} style={{paddingRight: "0px"}}>
                  <h1 style={{ float: "left" }}>Last Name</h1>
                  <Input style={{borderRadius: "5px"}} readOnly={true} value={userDetails?.lastName ? userDetails?.lastName : "-"}/>
                </Col>
              </Row>
            </Col>
            <Col span={21}>
              <Row justify="center" gutter={[40, 20]}>
                <Col xs={24} sm={24} md={9} lg={9} xl={9} style={{paddingRight: "0px"}}>
                  <h1 style={{ float: "left" }}>Email</h1>
                  <Input style={{borderRadius: "5px"}} readOnly={true} value={userDetails?.email ? userDetails?.email : "-"}/>
                </Col>
                <Col xs={24} sm={24} md={9} lg={9} xl={9} style={{paddingRight: "0px"}}>
                  <h1 style={{ float: "left" }}>Gender</h1>
                  <Input style={{borderRadius: "5px"}} readOnly={true} value={userDetails?.gender ? userDetails?.gender : "-"}/>
                </Col>
              </Row>
            </Col>
            <Col span={21}>
              <Row justify="center" gutter={[40, 20]}>
                <Col xs={24} sm={24} md={9} lg={9} xl={9} style={{paddingRight: "0px"}}>
                  <h1 style={{ float: "left" }}>Date of Birth</h1>
                  <Input style={{borderRadius: "5px"}} readOnly={true} value={userDetails?.dob ? userDetails?.dob : "-"} suffix={<img src={Images.EventsIcon}/>}/>
                </Col>
                <Col xs={24} sm={24} md={9} lg={9} xl={9} style={{paddingRight: "0px"}}>
                </Col>
              </Row>
            </Col>
          </Row>
      </HeaderComponent>
    );
  }
}

function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    getuserdetails: (id) => {
      dispatch(UserAction.GetUserDetails(id));
    },
  };
}

export default withRouter(
  connect(
    (storeState) => ({
      userDetails: storeState.UserReducer.userdetails,
    }),
    (mapStateToProps, mapDispatchToProps)
  )(UsersDetails)
);
