import React, { PureComponent } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { Colors } from "../../config";
import { useMedia } from "use-media";
import { Row, Col } from "antd";

class LineChartComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }
  static demoUrl =
    "https://codesandbox.io/s/line-chart-width-xaxis-padding-sujqi";
  renderLegend = (props) => {
    const { payload } = props;
    const { isLow } = this.props;
    //console.log("PROPS PAYLOAD ARE: ", isLow);
    const COLORS = [Colors.Cyan, Colors.Secondary, Colors.LightRed];
    return (
      <Row
        style={{
          display: "flex",
        }}
        justify="center"
        align="middle"
      >
        {payload.map((entry, index) => (
          <Row
            style={{
              display: "flex",
              marginLeft: "1.5rem",
              marginRight: "1.5rem",
            }}
          >
            <Col
              style={{
                borderRadius: 15,
                width: 15,
                height: 15,
                backgroundColor: entry.color,
                margin: 15,
              }}
            ></Col>
            <Col>
              <p
                style={{
                  color: entry.color,
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "12px",
                  fontSize: !isLow ? "0.46rem" : "0.8rem",
                }}
              >
                {"       " + entry.value}
              </p>
            </Col>
          </Row>
        ))}
      </Row>
    );
  };
  render() {
    //console.log("this props data: ", this.props.data);
    const {
      isEvent,
      isSubscription,
      data,
      eventData,
      subscribedData,
      isWide,
      isMedia,
      isMediaLarge,
    } = this.props;
    //console.log("is media: ", isMedia, isMediaLarge, (isMedia && !isMediaLarge));
    const payloadLegend = [
      {
        id: "em",
        value: isEvent ? "Event Creation" : "Ios",
        type: "circle",
        color: isEvent ? Colors.Cyan : Colors.Secondary,
      },
      {
        id: "r",
        value: isEvent ? "Event Completion" : "Android",
        type: "circle",
        color: isEvent ? Colors.Secondary : Colors.Cyan,
      },
    ];
    const isSubscribedPayload = [
      {
        id: "su",
        value: "Subscribed Users",
        type: "circle",
        color: Colors.Cyan,
      },
    ];
    return (
      <ResponsiveContainer width="96%" height="92%">
        <LineChart
          width={500}
          height={300}
          data={isEvent ? eventData : isSubscription ? subscribedData : data}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="name"
            padding={{ left: 30, right: 30 }}
            axisLine={{ stroke: "#fde8f1" }}
          />
          <YAxis padding={{ top: 5 }} axisLine={{ stroke: "#fde8f1" }} />
          <Tooltip />
          <Legend
            verticalAlign="bottom"
            height={isMedia && !isMediaLarge ? 52 : isWide ? 36 : 0}
            payload={isSubscription ? isSubscribedPayload : payloadLegend}
            content={this.renderLegend}
          />
          <Line
            type="monotone"
            dataKey={
              isEvent
                ? "Event Creation"
                : isSubscription
                ? "Subscribed Users"
                : "Ios"
            }
            stroke={isEvent || isSubscription ? "#82ca9d" : "#8884d8"}
            activeDot={{ r: 8 }}
          />
          <Line
            type="monotone"
            dataKey={isEvent ? "Event Completion" : "Android"}
            stroke={isEvent || isSubscription ? "#8884d8" : "#82ca9d"}
          />
        </LineChart>
      </ResponsiveContainer>
    );
  }
}

const MediaWrapper = (props) => {
  const isWide = useMedia({ minWidth: "510px" });
  const isLow = useMedia({ minWidth: "290px" });
  const isMedia = useMedia({ minWidth: "770px" });
  const isMediaLarge = useMedia({ minWidth: "1000px" });
  return (
    <LineChartComponent
      isWide={isWide}
      isLow={isLow}
      isMedia={isMedia}
      isMediaLarge={isMediaLarge}
      {...props}
    />
  );
};

export default React.memo(MediaWrapper);
