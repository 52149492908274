import { combineReducers } from 'redux';
import AuthReducer from './AuthReducer';
import FormReducer from '../reducers/appreducers/FormReducer';
import UserReducer from '../reducers/appreducers/UserReducer';
import EventReducer from '../reducers/appreducers/EventReducer';
import TransactionReducer from '../reducers/appreducers/TransactionReducer';
const RootReducer = combineReducers({
    AuthReducer,
    FormReducer,
    UserReducer,
    EventReducer,
    TransactionReducer
});

export default RootReducer;