import React from "react";
import { Layout, Row, Col } from "antd";
import Image from "../image";
import { Link } from "react-router-dom";
import { Colors, Images } from "../../config";
import "../../index.css";
const { Footer } = Layout;
const FooterNav = () => {
  const pathName = window.location.pathname;
  //console.log("pathname is:", pathName);
  const isUser = pathName.includes("users");
  const isForm = pathName.includes("forms");
  const isEvent = pathName.includes("events");
  const isTrans = pathName.includes("transactions");
  const isDashboard = !isUser && !isForm && !isEvent && !isTrans;
  //console.log("pathname is:", pathName);
  return (
    <>
      <Footer
        style={{
          backgroundColor: Colors.White,
          boxShadow: "0px 5px 20px #1E1E220C",
          position: "fixed",
          bottom: "0",
          width: "100%",
        }}
      >
        <Row>
          <Col span={24}>
            <Row gutter={[20, 0]}>
              <Col span={5}>
                <Link to="/">
                  <Image
                    src={
                      isDashboard
                        ? Images.DashboardIconAfterClicked
                        : Images.DashboardIcon
                    }
                    style={{ cursor: "pointer" }}
                  ></Image>
                </Link>
              </Col>
              <Col span={5}>
                <Link to="/users">
                  <Image
                    src={isUser ? Images.UserIconClicked : Images.UserIcon}
                    style={{ cursor: "pointer" }}
                  ></Image>
                </Link>
              </Col>
              <Col span={5}>
                <Link to="/events">
                  <Image
                    src={isEvent ? Images.EventIconClicked : Images.EventsIcon}
                    style={{ cursor: "pointer" }}
                  ></Image>
                </Link>
              </Col>
              <Col span={5}>
                <Link to="/forms">
                  <Image
                    src={isForm ? Images.FormIconClicked : Images.FormsIcon}
                    style={{ cursor: "pointer" }}
                  ></Image>
                </Link>
              </Col>
              <Col span={4}>
                <Link to="/transactions">
                  <Image
                    src={
                      isTrans
                        ? Images.TransactionIconFooter
                        : Images.TransactionsIcon
                    }
                    style={{ cursor: "pointer" }}
                  ></Image>
                </Link>
              </Col>
            </Row>
          </Col>
        </Row>
      </Footer>
    </>
  );
};

export default React.memo(FooterNav);
