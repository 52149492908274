import { Row, Col, Input } from 'antd'
import React from 'react';
import {Colors} from "../../config"
import styled from "styled-components";
import Image from "../../components/image"
import usersubs from "../../assets/images/usersubs.svg";
import userdelete from "../../assets/images/userdelete.svg";
const Search = styled(Input)`
  background-color: ${Colors.White};
  color: ${Colors.Black};
  border-radius: 10px;
  border-color: ${Colors.Primary};
  & ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: ${Colors.Primary};
  opacity: 1; /* Firefox */
  }
  & :-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: ${Colors.Primary};
  }
  & ::-ms-input-placeholder { /* Microsoft Edge */
  color: ${Colors.Primary};
  }
`;
const SearchBar = (props) => {
  return <Search  {...props}>
  </Search>;
}

export default React.memo(SearchBar)
