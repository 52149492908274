import React from "react";
import { Row, Col } from "antd";
import { PieChartComponent, LineChartComponent } from "..";
import { Colors } from "../../config";
import MonthRangePicker from "../MonthRangePicker";
import moment from "moment";
import { RiCalendarLine } from "react-icons/ri";
import { useDispatch } from "react-redux";
import useMedia from "use-media";
import { UserAction } from "../../store/actions";
const monthFormat = "MMM YYYY";

const ChartBox = (props) => {
  const {
    heading,
    isNoDate,
    newData,
    data,
    isEvent,
    eventData,
    isSubscription,
    subscribedData,
  } = props;
  const isWide = useMedia({ minWidth: "1208px" });
  const isLow = useMedia({ minWidth: "760px" });
  const isMid = useMedia({ minWidth: "1278px" });
  const dispatch = useDispatch();
  const makeApiCall = (range) => {
    const valueOfInput1 = range ? range[0]?.format() : console.log("NORANGE");
    const valueOfInput2 = range ? range[1]?.format() : console.log("NORANGE");
    const startDate = new Date(valueOfInput1);
    const endDate = new Date(valueOfInput2);
    const startMonth = startDate.getMonth() + 1;
    const startYear = startDate.getFullYear();
    const endMonth = endDate.getMonth() + 1;
    const endYear = endDate.getFullYear();
    const startYearCase = startYear.toString().substr(-2);
    const endYearCase = endYear.toString().substr(-2);
    //console.log("start date", startMonth, startYear, endMonth, endYear);
    if (isEvent) {
      dispatch(
        UserAction.GetEventStats(startMonth, startYear, endMonth, endYear)
      );
    } else if (isSubscription) {
      dispatch(
        UserAction.GetSubscribedUserCount(
          startMonth,
          startYearCase,
          endMonth,
          endYearCase
        )
      );
    } else {
      dispatch(
        UserAction.GetAppDownloadCount(
          startMonth,
          startYearCase,
          endMonth,
          endYearCase
        )
      );
    }
  };
  console.log("ISWIDE FOR THIS COMP: ", newData);
  return (
    <Row>
      <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={16}>
        <Row>
          <Col
            xs={24}
            sm={24}
            md={
              heading == "Subscription" ? 5 : heading == "App Downloads" ? 6 : 7
            }
            lg={
              heading == "Subscription" ? 5 : heading == "App Downloads" ? 6 : 7
            }
            xl={
              heading == "Subscription" ? 5 : heading == "App Downloads" ? 6 : 7
            }
            style={{ display: "flex", alignItems: "center" }}
          >
            {!isWide && isLow ? (
              <h6 style={{ float: "left" }}>
                <b>{heading}</b>
              </h6>
            ) : !isMid && isWide ? (
              <h5 style={{ float: "left" }}>
                <b>{heading}</b>
              </h5>
            ) : (
              <h3 style={{ float: "left" }}>
                <b>{heading}</b>
              </h3>
            )}
          </Col>
          {!isNoDate ? (
            <Col
              span={8}
              style={{
                marginBottom: "0.5rem",
                marginLeft: !isWide && isLow ? "0.2rem" : "0.4rem",
              }}
            >
              <MonthRangePicker
                allowClear={false}
                onChange={(e) => {
                  //console.log("RANGE PICKER VALUE IS: ", e);
                  makeApiCall(e);
                }}
                defaultValue={[moment().subtract(4, "months"), moment()]}
                format={monthFormat}
                picker="month"
                suffixIcon={
                  <RiCalendarLine
                    style={{ color: Colors.Primary, fontSize: "16px" }}
                  />
                }
              />
            </Col>
          ) : (
            <Col
              xs={24}
              sm={24}
              md={8}
              lg={8}
              xl={8}
              style={{ marginTop: "2.9rem" }}
            >
              {" "}
            </Col>
          )}
        </Row>
      </Col>
      <Col span={24}>
        <Row
          style={{
            boxShadow: "0px 5px 20px #1E1E2214",
            borderRadius: "10px",
            height: "25rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {/* <ResponsiveContainer >
                
            </ResponsiveContainer> */}
          {isNoDate ? (
            Object.keys(newData || {}).length && (newData || [])
            .map((val) => val.value)
            .reduce((total, value) => total + value, 0) > 0 ? <PieChartComponent newData={newData} />  : (
              <h4
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              >
                No Data Recieved!
              </h4>
            )
          ) : (
            <LineChartComponent
              data={data}
              isEvent={isEvent}
              eventData={eventData}
              isSubscription={isSubscription}
              subscribedData={subscribedData}
            />
          )}
        </Row>
      </Col>
    </Row>
  );
};

export default React.memo(ChartBox);
