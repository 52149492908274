import {
  SIGNIN,
  SIGNIN_SUCCESS,
  SIGNIN_FAILURE,
  LOADER_TRUE,
  LOADER_FALSE,
  SIGNOUT_SUCCESS,
  VERIFYCODE,
  VERIFYCODE_FAILURE,
  VERIFYCODE_SUCCESS,
  FORGETPASSWORD,
  FORGETPASSWORD_FAILURE,
  FORGETPASSWORD_SUCCESS,
  RESETPASSWORD,
  RESETPASSWORD_FAILURE,
  RESETPASSWORD_SUCCESS,
  CHANGEPASSWORD,
  CHANGEPASSWORD_FAILURE,
  CHANGEPASSWORD_SUCCESS,
} from "../constants";

const initialState = {
  user: localStorage.getItem("eventauth")
    ? JSON.parse(localStorage.getItem("eventauth"))?.user
    : null,
  //token: (JSON.parse(localStorage.getItem("eventauth"))).data.x_auth_token,
  signInLoader: false,
  resetPasswordLoader: false,
  verifyCodeLoader: false,
  forgetPasswordLoader: false,
  changePasswordLoader: false,
  isAuthenticated: localStorage.getItem("eventauth") ? true : false,
};

export default function AuthReducer(state = initialState, action) {
  switch (action.type) {
    case VERIFYCODE:
      state = {
        ...state,
        verifyCodeLoader: true,
      };
      break;

    case VERIFYCODE_SUCCESS:
      state = {
        ...state,
        verifyCodeLoader: false,
      };
      break;

    case VERIFYCODE_FAILURE:
      state = {
        ...state,
        verifyCodeLoader: false,
      };
      break;

    case RESETPASSWORD:
      state = {
        ...state,
        resetPasswordLoader: true,
      };
      break;

    case RESETPASSWORD_SUCCESS:
      state = {
        ...state,
        resetPasswordLoader: false,
      };
      break;

    case RESETPASSWORD_FAILURE:
      state = {
        ...state,
        resetPasswordLoader: false,
      };
      break;

    case CHANGEPASSWORD:
      state = {
        ...state,
        changePasswordLoader: true,
      };
      break;

    case CHANGEPASSWORD_SUCCESS:
      state = {
        ...state,
        changePasswordLoader: false,
      };
      break;

    case CHANGEPASSWORD_FAILURE:
      state = {
        ...state,
        changePasswordLoader: false,
      };
      break;

    case FORGETPASSWORD:
      state = {
        ...state,
        forgetPasswordLoader: true,
      };
      break;

    case FORGETPASSWORD_SUCCESS:
      state = {
        ...state,
        forgetPasswordLoader: false,
      };
      break;

    case FORGETPASSWORD_FAILURE:
      state = {
        ...state,
        forgetPasswordLoader: false,
      };
      break;

    case SIGNIN:
      state = {
        ...state,
        signInLoader: true,
      };
      break;

    case SIGNIN_SUCCESS:
      //console.log("ACTION.PAYLOAD: ", action.payload)
      state = {
        ...state,
        user: action.payload.user,
        signInLoader: false,
        isAuthenticated: true,
      };
      break;

    case SIGNIN_FAILURE:
      state = {
        ...state,
        signInLoader: false,
      };
      break;
    case SIGNOUT_SUCCESS: {
      state = {
        ...state,
        user: null,
        isAuthenticated: false,
      };
      break;
    }

    case LOADER_TRUE:
      state = {
        ...state,
        loader: true,
      };
      break;

    case LOADER_FALSE:
      state = {
        ...state,
        loader: false,
      };
      break;

    default:
      break;
  }
  //console.log("STATE", state, action, initialState)
  return state;
}
