// Dev base URL 
//export const API_BASE_URL = "https://eventpluz.5stardesigners.net:1023/dev/api/v1";

// UAT base URL 
// export const API_BASE_URL = "https://eventpluz.5stardesigners.net:1023/dev/api/v1";

// Live URL
export const API_BASE_URL = "https://api.eventpluz.com:3009/dev/api/v1";

//AUTH ENDPOINTS
export const LOGIN_ENDPOINT = "/auth/login";
export const FORGETPASSWORD_ENDPOINT = "/auth/forget-password";
export const VERIFYCODE_ENDPOINT = "/auth/verify-code";
export const RESETPASSWORD_ENDPOINT = "/auth/change-password";
export const CHANGEPASSWORD_ENDPOINT = "/auth/update-password";
export const LOGOUT_ENDPOINT = "/auth/logout";

//APP ENDPOINTS
export const GETFORMS_ENDPOINT = "/csv/tests";
export const UPLOADFORM_ENDPOINT = "/csv/upload";
export const DELETEFORM_ENDPOINT = "/csv";
export const GETUSERSTATS_ENDPOINT = "/admin/user-stats";
export const GETUSERS_ENDPOINT = "/admin/users";
export const GETUSERDETAILS_ENDPOINT = "/admin/users";
export const USERSTATUSUPDATE_ENDPOINT = "/admin/user/toggle";
export const GETAPPDOWNLOADCOUNT_ENDPOINT = "/admin/app-download-count";
export const GETUSERTYPECOUNT_ENDPOINT = "/admin/user/user-types";
export const GETSUBSCRIBEDUSERCOUNT_ENDPOINT = "/admin/subscription";
export const GETEVENTSTATSCOUNT_ENDPOINT = "/admin/event/event-created-count";
export const GETEVENTS_ENDPOINT = "/admin/events";
export const DELETEEVENTS_ENDPOINT = "/event";
export const GETTRANSACTIONS_ENDPOINT = "/admin/transactions";

const configVariables = {
    // appUrl,
    // appUrlForReview,
    //baseUrl: `${baseUrl}`,
    termsUrl: "",
    privacyUrl: "",
    contactSupportMail: "",
    //instructionsUrl: `${baseUrl}`,
}

const constantVariables = {
    monthNames: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
    messageForAppShare: "",
    subjectForAppShare: ""
}

export default {
    ...configVariables,
    ...constantVariables
}