import { UserAction } from "../../actions";
import { ApiCaller } from "../../../config";
import Info from "../../../components/message";
import {
  GETUSERSTATS_ENDPOINT,
  GETUSERS_ENDPOINT,
  GETUSERDETAILS_ENDPOINT,
  USERSTATUSUPDATE_ENDPOINT,
  GETAPPDOWNLOADCOUNT_ENDPOINT,
  GETUSERTYPECOUNT_ENDPOINT,
  GETSUBSCRIBEDUSERCOUNT_ENDPOINT,
  GETEVENTSTATSCOUNT_ENDPOINT,
} from "../../../config/variables";
import { put } from "redux-saga/effects";
export default class UserMiddleware {
  static *GetUserStats() {
    try {
      let res = yield ApiCaller.Get(GETUSERSTATS_ENDPOINT);
      if (res.status == 200) {
        yield put(UserAction.GetUserStatsSuccess(res.data.data));
      } else {
        yield put(UserAction.GetUserStatsFailure());
      }
    } catch (err) {
      yield put(UserAction.GetUserStatsFailure());
    }
  }

  static *GetUsers(payload) {
    const { limit, offset, typee, status, like } = payload;
    let hashtagQueryString = like ? like?.replaceAll("#", "%23") : "";
    let andString = hashtagQueryString
      ? hashtagQueryString?.replaceAll("&", "%26")
      : "";
    let percentString = andString ? andString?.replaceAll("%", "'%'") : "";
    let dotString = percentString ? percentString?.replaceAll(".", "'.'") : "";
    let underscoreString = dotString ? dotString?.replaceAll("_", "'_'") : "";
    try {
      let res = yield ApiCaller.Get(
        `${GETUSERS_ENDPOINT}?offset=${offset}&limit=${limit}&like=${underscoreString}&type=${typee}&status=${status}`
      );
      if (res.status == 200) {
        yield put(UserAction.GetUsersSuccess(res.data.data));
      } else {
        yield put(UserAction.GetUsersFailure());
        Info("error", res.data.error.message);
      }
    } catch (err) {
      yield put(UserAction.GetUsersFailure());
    }
  }

  static *GetUsersDetails(payload) {
    const { id } = payload;
    try {
      let res = yield ApiCaller.Get(`${GETUSERDETAILS_ENDPOINT}/${id}`);
      if (res.status == 200) {
        yield put(UserAction.GetUserDetailsSuccess(res.data.data));
      } else {
        yield put(UserAction.GetUserDetailsFailure());
        Info("error", res.data.data.message);
      }
    } catch (err) {
      yield put(UserAction.GetUserDetailsFailure());
    }
  }

  static *UserStatusUpdate(payload) {
    const { limit, offset, typee, userStatus, id, status, like } = payload;
    try {
      let res = yield ApiCaller.Put(`${USERSTATUSUPDATE_ENDPOINT}/${id}`, {
        status: status,
      });
      if (res.status == 200) {
        yield put(UserAction.UpdateUserStatusSuccess(res.data.data));
        Info("success", res.data.data);
        yield put(
          UserAction.GetUsers(
            limit,
            offset,
            typee,
            userStatus,
            like ? like : ""
          )
        );
      } else {
        yield put(UserAction.UpdateUserStatusFailure());
        Info("error", res.data.error);
      }
    } catch (err) {
      yield put(UserAction.UpdateUserStatusFailure());
    }
  }

  static *GetAppDownloadCount(payload) {
    const { startMonth, startYear, endMonth, endYear } = payload;
    try {
      let res = yield ApiCaller.Get(
        `${GETAPPDOWNLOADCOUNT_ENDPOINT}?startMonth=${startMonth}&endMonth=${endMonth}&startYear=${startYear}&endYear=${endYear}`
      );
      if (res.status == 200) {
        yield put(UserAction.GetAppDownloadCountSuccess(res.data.data));
      } else {
        yield put(UserAction.GetAppDownloadCountFailure());
        Info("error", res.data.error.message);
      }
    } catch (err) {
      yield put(UserAction.GetAppDownloadCountFailure());
    }
  }

  static *GetUserTypeCount() {
    try {
      let res = yield ApiCaller.Get(GETUSERTYPECOUNT_ENDPOINT);
      if (res.status == 200) {
        yield put(UserAction.GetUserTypeCountSuccess(res.data.data));
      } else {
        yield put(UserAction.GetUserTypeCountFailure());
        Info("error", res.data.error.message);
      }
    } catch (err) {
      yield put(UserAction.GetUserTypeCountFailure());
    }
  }

  static *GetSubscribedUserCount(payload) {
    const { startMonth, startYear, endMonth, endYear } = payload;
    try {
      let res = yield ApiCaller.Get(
        `${GETSUBSCRIBEDUSERCOUNT_ENDPOINT}/subscription-count?startMonth=${startMonth}&endMonth=${endMonth}&startYear=${startYear}&endYear=${endYear}`
      );
      if (res.status == 200) {
        yield put(UserAction.GetSubscribedUserCountSuccess(res.data.data));
      } else {
        yield put(UserAction.GetSubscribedUserCountFailure());
        Info("error", res.data.error.message);
      }
    } catch (err) {
      yield put(UserAction.GetSubscribedUserCountFailure());
    }
  }

  static *GetEventStatsCount(payload) {
    const { startMonth, startYear, endMonth, endYear } = payload;
    try {
      let res = yield ApiCaller.Get(
        `${GETEVENTSTATSCOUNT_ENDPOINT}?startMonth=${startMonth}&endMonth=${endMonth}&startYear=${startYear}&endYear=${endYear}`
      );
      if (res.status == 200) {
        yield put(UserAction.GetEventStatsSuccess(res.data.data));
      } else {
        yield put(UserAction.GetEventStatsFailure());
        Info("error", res.data.error.message);
      }
    } catch (err) {
      yield put(UserAction.GetEventStatsFailure());
    }
  }
}
