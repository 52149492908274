import React, { useEffect, useState } from "react";
import { Row, Col, Table, Switch } from "antd";
import { StatusBar } from "../../components";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { connect } from "react-redux";
import { LoadingOutlined } from "@ant-design/icons";
import { withRouter } from "react-router-dom";
import { UserAction } from "../../store/actions";
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const UserTable = (props) => {
  const { userType, offset, typee, like, userStatus } = props;
  const dispatch = useDispatch();
  const [offsett, setOffsett] = useState(offset);
  const changeStatus = (id, status) => {
    dispatch(UserAction.UpdateUserStatus(10, offset, typee, id, status, like, userStatus));
  };
  const columns = [
    {
      title: "",
      dataIndex: "thumbnailProfilePicture",
      key: "thumbnailProfilePicture",
      render: (text) => <img src={text} style={{ height: "3rem" }}></img>,
    },
    {
      title: "Name",
      dataIndex: "firstName",
      key: "firstName",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Events Created",
      dataIndex: "eventsCreatedCount",
      key: "eventsCreatedCount",
    },
    {
      title: "Events Joined",
      dataIndex: "eventJoinedCount",
      key: "eventJoinedCount",
    },
    {
      title: "Events Judged",
      dataIndex: "eventJudgedCount",
      key: "eventJudgedCount",
    },
    {
      title: "Actions",
      key: "action",
      render: (e, record) => (
        <Row
          gutter={[55, 10]}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
          justify="center"
        >
          <Col xs={24} sm={24} md={24} lg={2} xl={2}>
            <Switch
              checked={record.status == 1}
              onChange={() => {
                changeStatus(record.id, record.status == 1 ? 2 : 1);
                //console.log("record is: ", record);
              }}
            />
          </Col>
          <Col xs={24} sm={24} md={24} lg={14} xl={14}>
            <p style={{ textAlign: "left" }}>
              {record.status == 1 ? "User is Active" : "User is In-Active"}
            </p>
          </Col>
        </Row>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, record) => (
        <Row justify="center">
          <StatusBar status={record.subscriptionStatus} />
        </Row>
      ),
    },
  ];
  const [columnsNew, setColumnsNew] = useState(columns);
  useEffect(() => {
    userType == "eventmanager"
      ? columns.splice(4, 2)
      : userType == "judge"
      ? columns.splice(3, 2) && columns.pop()
      : userType == "rider"
      ? columns.splice(3, 1) && columns.splice(4, 1) && columns.pop()
      : columns.splice(3, 1) && columns.pop();
    setColumnsNew(columns);
  }, [userType, offset, like, userStatus]);
  useEffect(() => {
    setOffsett(offset);
  }, [offset]);
  const history = useHistory();
  const { userData, userTableLoader } = props;
  return (
    <Col style={{ overflowX: "auto" }} span={24}>
      <Table
        style={{ cursor: "pointer" }}
        columns={columnsNew}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              history.push({
                pathname: `/users/user-details/${record.id}`,
                state: {
                  id: record.id,
                },
              });
            },
          };
        }}
        pagination={false}
        dataSource={userData}
        loading={{ spinning: userTableLoader, indicator: antIcon}}
      />
    </Col>
  );
};
export default React.memo(
  withRouter(
    connect((storeState) => ({
      userTableLoader: storeState.UserReducer.userTableLoader,
    }))(UserTable)
  )
);
