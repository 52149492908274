import { TransactionAction } from "../../actions";
import { ApiCaller } from "../../../config";
import Info from "../../../components/message";
import {
  GETTRANSACTIONS_ENDPOINT
} from "../../../config/variables";
import { put } from "redux-saga/effects";

export default class TransactionMiddleware {
  static *GetTransactions(payload) {
    const { like, limit, offset, status } = payload;
    try {
      let res = yield ApiCaller.Get(
        `${GETTRANSACTIONS_ENDPOINT}?offset=${offset}&limit=${limit}&like=${like}&status=${status}`
      );
      console.log("RESSS: ", res);
      if (res.status == 200) {
        yield put(TransactionAction.GetTransactionsSuccess(res.data.data));
      } else {
        yield put(TransactionAction.GetTransactionsFailure());
        Info("error", res.data.error.message);
      }
    } catch (err) {
      yield put(TransactionAction.GetTransactionsFailure());
    }
  }

}
