import React from "react";
import { Modal, Row, Col } from "antd";
import { ButtonForm } from "../../components";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { EventAction } from "../../store/actions";
import { useDispatch } from "react-redux";
const ModalComponent = (props) => {
  const {
    id,
    like,
    limit,
    offset,
    isModalVisible,
    handleOk,
    handleCancel,
    content,
    deleteButtonLoader,
  } = props;
  const dispatch = useDispatch();
  const deleteEvent = () => {
    dispatch(
      EventAction.DeleteEvent(id, like, limit, offset, () => {
        handleOk();
      })
    );
  };
  return (
    <>
      <Modal
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        {...props}
        bodyStyle={{ padding: 35 }}
        wrapClassName="modalTheme"
      >
        <Col span={24}>
          {content}
          <Row gutter={[0, 15]}>
            <Col
              xs={24}
              sm={12}
              md={12}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <ButtonForm
                onClick={() => {
                  deleteEvent();
                }}
                loading={deleteButtonLoader}
              >
                Yes
              </ButtonForm>
            </Col>
            <Col
              xs={24}
              sm={12}
              md={12}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <ButtonForm
                onClick={() => {
                  handleCancel();
                }}
              >
                No
              </ButtonForm>
            </Col>
          </Row>
        </Col>
      </Modal>
    </>
  );
};
export default React.memo(
  withRouter(
    connect((storeState) => ({
      deleteButtonLoader: storeState.EventReducer.deleteButtonLoader,
    }))(ModalComponent)
  )
);
